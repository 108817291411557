import * as React from 'react';

import {
  EncryptedMessageDocument,
  MessageDocument,
  MessageState,
  ProcessedMessage,
} from 'src/models/Message';
import Message from '../Message';
import { User } from 'src/models/User';
import Loader from '../Loader';
import { ColorTheme } from 'src/features/XQSCWidget/types/Branding';

interface MessageListProps {
  currentUser: User;
  isMessageLoaderToggled: boolean;
  messageLoaders: EncryptedMessageDocument[];
  messages: ProcessedMessage[];
  onImageClick: (imgSrc: string) => void;
  onDeleteMessage: (message: ProcessedMessage) => void;
  theme: ColorTheme;
}

const MessageList: React.FC<MessageListProps> = ({
  currentUser,
  isMessageLoaderToggled,
  messageLoaders,
  messages,
  onImageClick,
  onDeleteMessage,
  theme,
}) => {
  const renderMessages = () =>
    [...messages, ...messageLoaders]?.map((message, index) => {
      const isCurrentUser = message.senderId === currentUser.id;

      const messageOrLoader = message as ProcessedMessage;

      const isLoading = () => {
        if (message.state === MessageState.LOADING) {
          return true;
        }

        if (!messageOrLoader.text) {
          if (
            messageOrLoader.fileAttachment &&
            messageOrLoader.fileAttachment.url
          ) {
            return false;
          }
          return true;
        }

        return false;
      };

      return (
        <Message
          isCurrentUser={isCurrentUser}
          isLoading={isLoading()}
          index={index}
          isTimestampVisible
          message={message as MessageDocument}
          onImageClick={onImageClick}
          onDeleteMessage={onDeleteMessage}
          theme={theme}
          key={index}
        />
      );
    });

  return (
    <div>
      {renderMessages()}
      <Loader isMessageLoaderToggled={isMessageLoaderToggled} />
    </div>
  );
};

export default MessageList;
