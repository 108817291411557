import React, { useRef, useState } from 'react';
import Button from 'src/features/XQSCWidget/components/Conversation/components/Button';
import Input from './components/Input';

import './OfflineScene.scoped.scss';
import { ReactComponent as XQIcon } from '../../../../../../../../assets/svg/logo.svg';
import { ReactComponent as CompleteIcon } from '../../../../../../../../assets/svg/complete.svg';

import { validateEmail } from 'src/features/XQSCWidget/utils/validate';
import CloseButton from 'src/features/XQSCWidget/components/shared/CloseButton';
interface OfflineSceneProps {
  onSubmitOfflineForm: (name: string, email: string, message: string) => void;
  onToggleWidgetVisibility: () => void;
  offlineMessage: string;
}

const preventEnter = (e: any, cb: any) => {
  if (e.keyCode === 13 || e.which === 13) {
    e.preventDefault();
    return false;
  }
  return cb;
};

const OfflineScene: React.FC<OfflineSceneProps> = ({
  onSubmitOfflineForm,
  onToggleWidgetVisibility,
  offlineMessage,
}) => {
  const inputRef = useRef<HTMLDivElement>(null!);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);

  const [isComplete, setComplete] = useState<boolean>(false);

  return (
    <div className="onboarding-container">
      <div className="top-container">
        <div className="title-container">
          {process.env.REACT_APP_IS_XQMSG && (
            <span className="company-name">XQMSG.CO</span>
          )}
          <span className="title">Chat with us!</span>
        </div>
        <CloseButton onClick={onToggleWidgetVisibility} />
      </div>
      <div className="center-container">
        {isComplete ? (
          <div className="offline-box">
            <div
              className="offline-box-title-container"
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <CompleteIcon style={{ marginBottom: 20 }} />
              <span className="auth-title" style={{ marginBottom: 20 }}>
                Your inquiry has been submitted.
              </span>
              <span className="auth-subtitle" style={{ textAlign: 'center' }}>
                Please expect an email response once our working hours resume.
                Talk soon!
              </span>
            </div>
          </div>
        ) : (
          <div className="offline-box">
            <div className="offline-box-title-container">
              <span className="auth-title">We are currently offline.</span>
              <span className="auth-subtitle">{offlineMessage}</span>
            </div>
            <div className="offline-box-content-container">
              <div className="offline-input">
                <Input
                  handlerOnChange={(event: any) =>
                    preventEnter(event, setName(event.target.textContent))
                  }
                  handlerOnKeyDown={(event: any) =>
                    preventEnter(event, setName(event.target.textContent))
                  }
                  handlerOnKeyPress={(event: any) =>
                    preventEnter(event, setName(event.target.textContent))
                  }
                  handlerOnKeyUp={(event: any) =>
                    preventEnter(event, setName(event.target.textContent))
                  }
                  inputRef={inputRef}
                  isInputDisabled={false}
                  placeholder="Name"
                  containerStyle={{ cursor: 'text', marginBottom: 15 }}
                />
              </div>
              <div className="offline-input">
                <Input
                  handlerOnChange={(event: any) => {
                    if (showEmailError) {
                      setShowEmailError(false);
                    }

                    return preventEnter(
                      event,
                      setEmail(event.target.textContent)
                    );
                  }}
                  handlerOnKeyDown={(event: any) =>
                    preventEnter(event, setEmail(event.target.textContent))
                  }
                  handlerOnKeyPress={(event: any) =>
                    preventEnter(event, setEmail(event.target.textContent))
                  }
                  handlerOnKeyUp={(event: any) =>
                    preventEnter(event, setEmail(event.target.textContent))
                  }
                  inputRef={inputRef}
                  isInputDisabled={false}
                  placeholder="Email"
                  containerStyle={{ cursor: 'text', marginBottom: 15 }}
                />
                {showEmailError && (
                  <p className="error-message">
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              <div className="offline-input">
                <Input
                  handlerOnChange={(event: any) =>
                    preventEnter(event, setMessage(event.target.textContent))
                  }
                  handlerOnKeyDown={(event: any) =>
                    preventEnter(event, setMessage(event.target.textContent))
                  }
                  handlerOnKeyPress={(event: any) =>
                    preventEnter(event, setMessage(event.target.textContent))
                  }
                  handlerOnKeyUp={(event: any) =>
                    preventEnter(event, setMessage(event.target.textContent))
                  }
                  inputRef={inputRef}
                  isInputDisabled={false}
                  placeholder="What can we help you with today?"
                  containerStyle={{
                    cursor: 'text',
                    marginBottom: 15,
                    height: 150,
                  }}
                />
                <p
                  className={`character-count ${
                    message.length > 600 ? 'error' : ''
                  }`}
                >
                  {600 - message.length} characters left
                </p>
              </div>
              <Button
                onClick={() => {
                  if (!validateEmail(email)) {
                    return setShowEmailError(true);
                  }

                  onSubmitOfflineForm(name, email, message);
                  setComplete(true);
                }}
                isDisabled={
                  !name.length ||
                  !email.length ||
                  !message.length ||
                  message.length > 600
                }
                style={{ backgroundColor: '#0082ff' }}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="configure-link">
        {process.env.REACT_APP_IS_XQMSG && <XQIcon />}
        <p>
          Powered by{' '}
          <a
            rel="noopener noreferrer"
            href="https://manage.xqmsg.com/applications/secure-chat-widget/configure"
          >
            XQ
          </a>
        </p>
      </div>
    </div>
  );
};

export default OfflineScene;
