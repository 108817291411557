import React from 'react';
import cn from 'classnames';
import { AnyFunction } from 'src/utils/types';

import './Input.scoped.scss';

interface InputProps {
  handlerOnChange: AnyFunction;
  handlerOnKeyDown: AnyFunction;
  handlerOnKeyPress: AnyFunction;
  handlerOnKeyUp: AnyFunction;
  inputRef?: React.MutableRefObject<HTMLDivElement>;
  isInputDisabled: boolean;
  placeholder: string;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
}

const Input: React.FC<InputProps> = ({
  handlerOnChange,
  handlerOnKeyDown,
  handlerOnKeyPress,
  handlerOnKeyUp,
  inputRef,
  isInputDisabled,
  placeholder,
  containerStyle,
  inputStyle,
}) => {
  return (
    <div
      className={cn('auth-input-container', {
        'auth-input-container-disabled': isInputDisabled,
      })}
      style={{ ...containerStyle }}
    >
      <div
        spellCheck
        className="auth-input"
        role="textbox"
        contentEditable={!isInputDisabled}
        ref={inputRef}
        placeholder={placeholder}
        onInput={handlerOnChange}
        onKeyPress={handlerOnKeyPress}
        onKeyUp={handlerOnKeyUp}
        onKeyDown={handlerOnKeyDown}
        style={{ ...inputStyle }}
      />
    </div>
  );
};

export default Input;
