export let isChrome = navigator.userAgent.indexOf('Chrome') > -1;
export const isExplorer = navigator.userAgent.indexOf('MSIE') > -1;
export const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
export let isSafari = navigator.userAgent.indexOf('Safari') > -1;
export const isOpera = navigator.userAgent.toLowerCase().indexOf('op') > -1;
if (isChrome && isSafari) {
  isSafari = false;
}
if (isChrome && isOpera) {
  isChrome = false;
}
