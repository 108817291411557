import 'core-js/stable';
import 'regenerator-runtime/runtime';

import XQSCWidget from './';

import { AnyFunction } from '../../utils/types';
import { useLoadConfig } from 'src/hooks/useLoadConfig';
import '../../scss/main.scss';

type XQSCWidgetProps = {
  handleQuickButtonClicked?: AnyFunction;
  handleSubmit?: AnyFunction;
  handleTextInputChange?: (event: any) => void;
  isAutoFocused?: boolean;
  isImagePreviewMode?: boolean;
  isImagePreviewVisible?: boolean;
  isNotificationBadgeVisible?: boolean;
  isResizeable?: boolean;
  launcher?: AnyFunction;
  launcherOpenImg?: string;
  launcherOpenLabel?: string;
  sendButtonAlt?: string;
  senderPlaceHolder?: string;
  subtitle?: string;
  title?: string;
  titleAvatar?: string;
  zoomStep?: number;
} & typeof defaultProps;

const XQSCWIdget = ({
  handleQuickButtonClicked,
  handleSubmit,
  handleTextInputChange,
  isAutoFocused,
  isImagePreviewMode,
  isImagePreviewVisible,
  isNotificationBadgeVisible,
  isResizeable,
  launcher,
  launcherOpenImg,
  launcherOpenLabel,
  sendButtonAlt,
  senderPlaceHolder,
  subtitle,
  titleAvatar,
  zoomStep,
}: XQSCWidgetProps) => {
  const widgetAppScript = document.getElementById('xq-widget-embed');
  const configurationId = widgetAppScript?.getAttribute('data-config-id') || '';

  const isLoaded = useLoadConfig(configurationId);

  if (!isLoaded) {
    return null;
  }

  return (
    <XQSCWidget
      customLauncher={launcher}
      handleQuickButtonClicked={handleQuickButtonClicked}
      handleSubmit={handleSubmit}
      handleTextInputChange={handleTextInputChange}
      isAutoFocused={isAutoFocused}
      isImagePreviewMode={isImagePreviewMode}
      isImagePreviewVisible={isImagePreviewVisible}
      isNotificationBadgeVisible={isNotificationBadgeVisible}
      isResizeable={isResizeable}
      launcherOpenImg={launcherOpenImg}
      launcherOpenLabel={launcherOpenLabel}
      sendButtonAlt={sendButtonAlt}
      senderPlaceHolder={senderPlaceHolder}
      subtitle={subtitle}
      titleAvatar={titleAvatar}
      zoomStep={zoomStep}
    />
  );
};

const defaultProps = {
  isAutoFocused: true,
  isImagePreviewMode: false,
  isImagePreviewVisible: false,
  isNotificationBadgeVisible: true,
  isResizeable: false,
  isTimestampVisible: true,
  launcherOpenImg: '',
  launcherOpenLabel: 'Open chat',
  sendButtonAlt: 'Send',
  senderPlaceHolder: 'Write a message...',
  subtitle: 'Powered by XQ',
  title: 'Secure Support Chat',
  zoomStep: 80,
};
XQSCWIdget.defaultProps = defaultProps;

export default XQSCWIdget;
