// import { useSelector } from 'react-redux';
// import { RootState } from 'src/store';
import { AnyFunction } from 'src/utils/types';

import './QuickButtons.scoped.scss';

type QuickButtonsProps = {
  onQuickButtonClicked?: AnyFunction;
};

const QuickButtons = ({ onQuickButtonClicked }: QuickButtonsProps) => {
  // const buttons = useSelector(
  //   (state: RootState) => state.quickButtons.quickButtons
  // );

  const buttons = [] as any[];

  const getComponentToRender = (button: any) => {
    const ComponentToRender = button.component;
    return (
      <ComponentToRender
        onQuickButtonClicked={onQuickButtonClicked}
        button={button}
      />
    );
  };

  if (!buttons.length) return null;

  return (
    <div className="quick-buttons-container">
      <ul className="quick-buttons">
        {buttons.map((button, index) => (
          <li className="quick-list-button" key={`${button.label}-${index}`}>
            {getComponentToRender(button)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuickButtons;
