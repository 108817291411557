import { combineReducers } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import conversationSlice, { resetConversationState } from './conversation';
import settingsSlice, { resetSettingState } from './settings';
import userSlice, { resetUserState } from './user';

export const rootReducer = combineReducers({
  conversation: conversationSlice,
  settings: settingsSlice,
  user: userSlice,
});

export const resetState = (dispatch: Dispatch) => {
  dispatch(resetConversationState());
  dispatch(resetSettingState());
  dispatch(resetUserState());
};
