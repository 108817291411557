import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import { createMessage } from 'src/services/chat';
import { ProcessedConversation } from 'src/models/Conversation';
import { FileAttachment } from 'src/models/Message';
import {
  setFileAttachments,
  setIsSendingMessage,
  setMessageInput,
} from 'src/store/reducers/conversation';
import { uploadEncryptedFile } from 'src/services/firebase';
import { encryptFile } from 'src/services/XQSDK';

/**
 * A function utilized to send a chat message and store the message and its content into the DB
 * @param pendingMessageInput - string; the message to be sent.
 * @returns
 */

export const onSendMessage = createAsyncThunk<null | void>(
  'conversations/create',
  async (_, { dispatch, getState }) => {
    const {
      conversation: { currentConversation, messageInput, fileAttachments },
      user: { currentUser },
    } = getState() as RootState;

    // Prevent user from sending a message while a message is pending
    if (!currentUser) {
      return null;
    }

    const pendingMessageInput = messageInput;
    dispatch(setMessageInput(''));
    dispatch(setIsSendingMessage(true));

    const conversation = currentConversation as ProcessedConversation;
    if (messageInput.length) {
      await createMessage(
        conversation,
        currentUser,
        {
          messageInput: pendingMessageInput,
        },
        dispatch
      );
    }

    if (fileAttachments.length) {
      const recipientEmails = currentConversation.recipients.map(recipient =>
        recipient.settings.isAliasUser
          ? recipient.email + '@alias.local'
          : recipient.email
      );

      fileAttachments.map(
        async (attachment: { file: File; name: string; preview: string }) => {
          const encryptedFile = await encryptFile(
            attachment.file,
            recipientEmails
          );

          const uploadedFileAttachment = (await uploadEncryptedFile(
            encryptedFile as File,
            attachment.file,
            dispatch
          )) as FileAttachment;

          await createMessage(
            conversation,
            currentUser,
            {
              messageInput: '',
              fileAttachment: uploadedFileAttachment,
            },
            dispatch
          );
        }
      );
    }

    dispatch(setFileAttachments([]));
    dispatch(setIsSendingMessage(false));
  }
);
