import cn from 'classnames';

import './Loader.scoped.scss';

type LoaderProps = {
  isMessageLoaderToggled: boolean;
};

const Loader = ({ isMessageLoaderToggled }: LoaderProps) => (
  <div className={cn('loader', { active: isMessageLoaderToggled })}>
    <div className="loader-container">
      <span className="loader-dots"></span>
      <span className="loader-dots"></span>
      <span className="loader-dots"></span>
    </div>
  </div>
);

export default Loader;
