import { baseUserDocument, User, UserModelFields } from 'src/models/User';

import {
  AUTH,
  AvailableCollections,
  FIRESTORE,
  firebaseAuthOperations,
  firestoreOperations,
} from './firebase';

const { collection, query, where, getDocs, setDoc, doc } = firestoreOperations;

const { ANONYMOUS_SIGN_IN, ANONYMOUS_SIGN_OUT } = firebaseAuthOperations;

export const signInAnonymouslyViaFirebase = async () => {
  await ANONYMOUS_SIGN_IN(AUTH);
};

export const signOut = () => {
  ANONYMOUS_SIGN_OUT;
};

const randomOneToFive = Math.floor(Math.random() * 6);

/**
 * A function utilized to create and save a user document to the `users` collection in firebase for a new user.
 * If a user with the same email already exists we simply cancel the save operation.
 * @param user a User document
 * @returns Promise<void | null>
 *
 */
export const createNewUserViaFirestore = async (
  email: string,
  userName: string
) => {
  const q = query(
    collection(FIRESTORE, AvailableCollections.USERS),
    where(UserModelFields.EMAIL, '==', email)
  );

  const userQuerySnapshot = await getDocs(q);

  const isNewUser: boolean = userQuerySnapshot.empty;

  if (!isNewUser) {
    return null;
  }

  const newUserDocumentReferenceId = doc(
    collection(FIRESTORE, AvailableCollections.USERS)
  ).id;

  const newUserDocument: User = {
    ...baseUserDocument,
    avatar: `icon_avatar_chat_${randomOneToFive}.svg`,
    createdAt: new Date(),
    email,
    name: userName,
    id: newUserDocumentReferenceId,
  };

  await setDoc(
    doc(FIRESTORE, AvailableCollections.USERS, newUserDocument.id),
    newUserDocument
  );

  return newUserDocument;
};
