import {
  FirestoreTimestamp,
  // setTimestamp
} from 'src/services/firebase';
import firebase from 'firebase/compat/app';

/**
 * An interface representing a file attachment in a given `Message`
 * @param url - the reference url for the file
 * @param type - either 'file' or 'image'. We typically display file previews of 'image' types.
 * @param isViewable - a boolean value representing whether or not the file can be viewed/displayed
 * @param name - name of the given file, this typically includes a file ext.
 */
export interface FileAttachment {
  url: string;
  type: string;
  name: string;
  size: number;
}

/**
 * An interface representing a file attachment in a given `Message`
 * @param url - the reference url for the link
 * @param title - the title provided by the metadata of the referenced url
 * @param description - the description provided by the metadata of the referenced url
 * @param image - the image provided by the metadata of the referenced url
 */
export interface URLAttachment {
  url: string;
  title: string;
  description: string;
  image: string;
}

/**
 * An enum representing the current state of a message
 */
export enum MessageState {
  LOADING = 'loading', // User has just sent a message in that chat box
  READABLE = 'readable', // Message has been processed and decrypted
  ACTIVE = 'active', // Message is still encrypted
  DELETED = 'deleted', // Message has been deleted
  FAILED_ENCRYPTION = 'failed_encryption', // Message failed to encrypt
  FAILED_DECRYPTION = 'failed_decryption', // Message failed to decrypt
}

/**
 * An interface representing a `DecryptedMessagePayload`, the decrypted variant of the `payload` field of an `EncryptedMessage`
 * @param expirationHours - a number representing the time in hours until expiration of the message
 * @param fileAttachment - a Map representing the file attachment sent along with the message
 * @param text - the encrypted text of the Message
 * @param urlAttachments - an array field of Map objects representing the url attachments in a given `Message`
 */
export interface DecryptedMessagePayload {
  expirationHours: number;
  fileAttachment: FileAttachment;
  text: string;
  urlAttachments: URLAttachment[];
}

/**
 * An interface representing a stored `EncryptedMessage` document in Firestore.
 * @param date - A firestore timestamp (see `FirestoreTimestamp` type for more info)
 * @param id - the ID of the Message
 * @param locatorToken - the token used as part of the decryption process
 * @param payload - the encrypted payload of the Message
 * @param senderId - the ID of the parent user of the message
 * @param state - An enum value representing the current state of a message
 */
export interface EncryptedMessageDocument {
  date: FirestoreTimestamp;
  id: string;
  locatorToken: string;
  payload: string;
  senderId: string;
  state: MessageState;
}

/**
 * An interface representing a decrypted `EncryptedMessage` document in Firestore.
 * @param date - A firestore timestamp (see `FirestoreTimestamp` type for more info)
 * @param expirationHours - a number representing the time in hours until expiration of the message
 * @param fileAttachment - a Map representing the file attachment sent along with the message
 * @param id - the ID of the Message
 * @param locatorToken - the token used as part of the decryption process
 * @param senderId - the ID of the parent user of the message
 * @param state - An enum value representing the current state of a message
 * @param text - the encrypted text of the Message
 * @param urlAttachments - an array field of Map objects representing the url attachments in a given `Message`
 */
export interface MessageDocument {
  date: FirestoreTimestamp;
  expirationHours: number;
  fileAttachment: FileAttachment;
  id: string;
  locatorToken: string;
  senderId: string;
  state: MessageState;
  text: string;
  urlAttachments: URLAttachment[];
}

/**
 * An interface representing the processed/formatted version of the `MessageDocument`. This will include sender metadata, the status of the decryption/fetching operation, etc.
 * We format our `MessageDocument`s so to display them in our components
 *
 * @param isExpired - boolean value representing whether or not the Message is expired
 * @param sender - a Map representing the sender and their metadata
 * @param status - a number value reprenting the status of the decryption/fetching operation
 */
export interface ProcessedMessage extends MessageDocument {
  isExpired: boolean;
  sender: {
    avatar: string;
    id: string;
    name: string;
  };
  status: number;
}

/**
 * A base template utilized to upload a `MessageDocument` to Firestore
 */
export const baseMessageDocument: MessageDocument = {
  date: ({
    seconds: '',
    nanoseconds: '',
  } as unknown) as FirestoreTimestamp,
  expirationHours: 24, // default to 1 day expiration
  fileAttachment: {
    url: '',
    type: '',
    name: '',
    size: 0,
  },
  id: '',
  locatorToken: '',
  senderId: '',
  state: MessageState.ACTIVE,
  text: '',
  urlAttachments: [],
};

export const baseAutomatedMessage: ProcessedMessage = {
  ...baseMessageDocument,
  id: 'automated-msg-id',
  isExpired: false,
  sender: {
    avatar: '',
    id: 'a-user',
    name: 'automated-message',
  },
  status: 200,
  date: firebase.firestore.Timestamp.now(),
};
