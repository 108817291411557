import './NotificationBadge.scoped.scss';

type NotificationBadgeProps = {
  badge: number;
};

const NotificationBadge = ({ badge }: NotificationBadgeProps) => {
  return badge > 0 ? <span className="xqscw-badge">{badge}</span> : null;
};

export default NotificationBadge;
