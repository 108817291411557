import { useRef, useState, useEffect } from 'react';

import cn from 'classnames';

import Header from './components/Header';
import Body from './components/Body';
import InputBar from './components/InputBar';
import QuickButtons from './components/QuickButtons';

import { AnyFunction } from 'src/utils/types';

import './Conversation.scoped.scss';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'src/models/User';
import { processMessage, streamConversationMessages } from 'src/services/chat';
import {
  addMessage,
  setCurrentImagePreview,
  setNotificationCount,
} from 'src/store/reducers/conversation';
import { removeMessageLoader } from 'src/store/reducers/conversation';
import OnboardingScene from './components/Body/components/OnboardingScene';
import { ProcessedMessage } from 'src/models/Message';
import { ColorTheme } from '../../types/Branding';
import OfflineScene from './components/Body/components/OfflineScene';
import { RootState } from 'src/store';

interface InputBarRef {
  onSelectEmoji: (event: any) => void;
}

type ConversationProps = {
  className: string;
  currentUser: User;
  emojis?: boolean;
  isAutoFocused: boolean;
  isInputDisabled: boolean;
  isOnline: boolean;
  isResizeable?: boolean;
  onQuickButtonClicked?: AnyFunction;
  onToggleWidgetVisibility: () => void;
  onDeleteMessage: (message: ProcessedMessage) => void;
  onSendMessage: AnyFunction;
  onSetCurrentUser: (name?: string, email?: string) => void;
  onSubmitOfflineForm: (name: string, email: string, message: string) => void;
  onTextInputChange?: (event: any) => void;
  sendButtonAlt: string;
  senderPlaceHolder: string;
  subtitle: string;
  theme: ColorTheme;
  title: string;
  titleAvatar?: string;
  toggleWidgetVisibility: AnyFunction;
};

const Conversation = ({
  className,
  currentUser,
  isAutoFocused,
  isInputDisabled,
  isOnline,
  isResizeable,
  onToggleWidgetVisibility,
  onDeleteMessage,
  onQuickButtonClicked,
  onSendMessage,
  onSetCurrentUser,
  onSubmitOfflineForm,
  onTextInputChange,
  sendButtonAlt,
  senderPlaceHolder,
  subtitle,
  theme,
  title,
  titleAvatar,
}: ConversationProps) => {
  const dispatch = useDispatch();
  const [containerDiv, setContainerDiv] = useState<HTMLElement | null>();

  let startX: number, startWidth: number;

  const {
    conversation: {
      automatedMessages,
      currentConversation,
      messages,
      messageLoaders,
      isSendingMessage,
    },
    settings: { isMessageLoaderToggled, isChatVisible },
    user: { isOnboarded },
  } = useSelector((state: RootState) => ({
    conversation: state.conversation,
    settings: state.settings,
    user: state.user,
  }));

  useEffect(() => {
    const containerDiv = document.getElementById(
      'xqscw-conversation-container'
    );
    setContainerDiv(containerDiv);
  }, []);

  useEffect(() => {
    if (currentConversation.recipients.length) {
      const unsubscribe = streamConversationMessages(
        currentConversation.id,
        async encryptedMessageDocument => {
          const currentMessageIds = messages.map(message => message.id);
          const isCurrentUser =
            encryptedMessageDocument.senderId === currentUser.id;

          const processedMessageToDisplay = await processMessage(
            encryptedMessageDocument
          );

          // Will only occur for files - text messages are almost instantaneous
          if (!currentMessageIds.includes(encryptedMessageDocument.id)) {
            dispatch(removeMessageLoader(encryptedMessageDocument));
          }

          if (!isChatVisible && !isCurrentUser) {
            dispatch(setNotificationCount(1));
          }

          dispatch(addMessage(processedMessageToDisplay));
        }
      );

      return () => {
        unsubscribe();
      };
    }
    // eslint-disable-next-line
  }, [currentConversation]);

  const initResize = (e: { clientX: number }) => {
    if (isResizeable) {
      startX = e.clientX;
      if (document.defaultView && containerDiv) {
        startWidth = parseInt(
          document.defaultView.getComputedStyle(containerDiv).width
        );
        window.addEventListener('mousemove', resize, false);
        window.addEventListener('mouseup', stopResize, false);
      }
    }
  };

  const resize = (e: { clientX: number }) => {
    if (containerDiv) {
      containerDiv.style.width = startWidth - e.clientX + startX + 'px';
    }
  };

  const stopResize = () => {
    window.removeEventListener('mousemove', resize, false);
    window.removeEventListener('mouseup', stopResize, false);
  };

  const inputBarRef = useRef<InputBarRef>(null!);

  const renderBody = () => {
    if (!isOnline) {
      return (
        <OfflineScene
          onSubmitOfflineForm={onSubmitOfflineForm}
          onToggleWidgetVisibility={onToggleWidgetVisibility}
          offlineMessage={automatedMessages.offline}
        />
      );
    }

    if (!isOnboarded) {
      return (
        <OnboardingScene
          onSetCurrentUser={onSetCurrentUser}
          onToggleWidgetVisibility={onToggleWidgetVisibility}
        />
      );
    }

    return (
      <>
        <Header
          theme={theme}
          title={title}
          subtitle={subtitle}
          titleAvatar={titleAvatar}
          onToggleWidgetVisibility={onToggleWidgetVisibility}
        />
        <Body
          currentUser={currentUser}
          isMessageLoaderToggled={isMessageLoaderToggled}
          isOnboarded={isOnboarded}
          isSendingMessage={isSendingMessage}
          messageLoaders={messageLoaders}
          messages={messages}
          onImageClick={(imageSrc: string) => {
            dispatch(
              setCurrentImagePreview({
                src: imageSrc,
                alt: 'img',
                width: 200,
                height: 200,
                visible: true,
              })
            );
          }}
          onDeleteMessage={onDeleteMessage}
          theme={theme}
        />

        <QuickButtons onQuickButtonClicked={onQuickButtonClicked} />
        <InputBar
          buttonAlt={sendButtonAlt}
          isAutoFocused={isAutoFocused}
          isInputDisabled={isInputDisabled}
          onSendMessage={onSendMessage}
          onTextInputChange={onTextInputChange}
          placeholder={senderPlaceHolder}
          ref={inputBarRef}
        />
      </>
    );
  };

  return (
    <div
      id="xqscw-conversation-container"
      onMouseDown={initResize}
      className={cn('xqscw-conversation-container', className)}
      aria-live="polite"
    >
      {isResizeable && <div className="xqscw-conversation-resizer" />}
      {renderBody()}
    </div>
  );
};

export default Conversation;
