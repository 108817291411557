import React, { useRef, useState } from 'react';
import Button from 'src/features/XQSCWidget/components/Conversation/components/Button';
import Input from './components/Input';

import './OnboardingScene.scoped.scss';
import { ReactComponent as XQIcon } from '../../../../../../../../assets/svg/logo.svg';
import { validateEmail } from 'src/features/XQSCWidget/utils/validate';
import CloseButton from 'src/features/XQSCWidget/components/shared/CloseButton';

// const xqLogo = require('src/assets/xq-logo.png');
interface OnboardingSceneProps {
  onSetCurrentUser: (name?: string, email?: string) => void;
  onToggleWidgetVisibility: () => void;
}

const preventEnter = (e: any, cb: any) => {
  if (e.keyCode === 13 || e.which === 13) {
    e.preventDefault();
    return false;
  }
  return cb;
};

const OnboardingScene: React.FC<OnboardingSceneProps> = ({
  onSetCurrentUser,
  onToggleWidgetVisibility,
}) => {
  const inputRef = useRef<HTMLDivElement>(null!);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);

  return (
    <div className="onboarding-container">
      <div className="top-container">
        <div className="title-container">
          {process.env.REACT_APP_IS_XQMSG && (
            <span className="company-name">XQMSG.CO</span>
          )}
          <span className="title">Chat with us!</span>
        </div>
        <CloseButton onClick={onToggleWidgetVisibility} />
      </div>

      <div className="center-container">
        <div className="auth-box quick-start">
          <div className="auth-box-title-container">
            <span className="auth-title">Quick start:</span>
            <span className="auth-subtitle">Jump into a chat as a guest.</span>
          </div>
          <div className="auth-box-content-container">
            <Button
              onClick={() => onSetCurrentUser()}
              isDisabled={false}
              style={{ backgroundColor: '#0082ff' }}
            >
              Chat Now
            </Button>
          </div>
        </div>
        <div className="auth-box login">
          <div className="auth-box-title-container">
            <span className="auth-title">Personalize your experience:</span>
            <span className="auth-subtitle">
              Provide your name and email for further correspondence with our
              team.
            </span>
          </div>
          <div className="auth-box-content-container">
            <div className="onboarding-input-container">
              <Input
                handlerOnChange={(event: any) =>
                  preventEnter(event, setName(event.target.textContent))
                }
                handlerOnKeyDown={(event: any) =>
                  preventEnter(event, setName(event.target.textContent))
                }
                handlerOnKeyPress={(event: any) =>
                  preventEnter(event, setName(event.target.textContent))
                }
                handlerOnKeyUp={(event: any) =>
                  preventEnter(event, setName(event.target.textContent))
                }
                inputRef={inputRef}
                isInputDisabled={false}
                placeholder="name"
                containerStyle={{ cursor: 'text', marginBottom: 15 }}
                inputStyle={{
                  fontSize: 14,
                }}
              />
            </div>
            <div className="onboarding-input-container">
              <Input
                handlerOnChange={(event: any) => {
                  if (showEmailError) {
                    setShowEmailError(false);
                  }

                  return preventEnter(
                    event,
                    setEmail(event.target.textContent)
                  );
                }}
                handlerOnKeyDown={(event: any) =>
                  preventEnter(event, setEmail(event.target.textContent))
                }
                handlerOnKeyPress={(event: any) =>
                  preventEnter(event, setEmail(event.target.textContent))
                }
                handlerOnKeyUp={(event: any) =>
                  preventEnter(event, setEmail(event.target.textContent))
                }
                inputRef={inputRef}
                isInputDisabled={false}
                placeholder="email"
                containerStyle={{
                  cursor: 'text',
                  marginBottom: 15,
                }}
                inputStyle={{
                  fontSize: 14,
                }}
              />
              {showEmailError && (
                <p className="error-message">
                  Please enter a valid email address.
                </p>
              )}
            </div>

            <div id="start-chat-button">
              <Button
                onClick={() => {
                  if (!validateEmail(email)) {
                    return setShowEmailError(true);
                  }

                  onSetCurrentUser(name, email);
                }}
                isDisabled={!name.length || !email.length}
                style={{ backgroundColor: '#0082ff' }}
                disableHoverEffect
              >
                Start Chat
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="configure-link">
        {process.env.REACT_APP_IS_XQMSG && <XQIcon />}
        <p>
          Powered by{' '}
          <a
            rel="noopener noreferrer"
            href="https://manage.xqmsg.com/applications/secure-chat-widget/configure"
          >
            XQ
          </a>
        </p>
      </div>
    </div>
  );
};

export default OnboardingScene;
