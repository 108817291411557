import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';

import {
  getCaretIndex,
  isFirefox,
  updateCaret,
  insertNodeAtCaret,
  getSelection,
} from 'src/utils/contentEditable';
import { ReactComponent as SendButtonIcon } from 'src/assets/svg/send-button.svg';
import { ReactComponent as SendButtonDisabledIcon } from 'src/assets/svg/send-button-disabled.svg';
import { ReactComponent as FileLogo } from 'src/assets/svg/file-logo.svg';
import { ReactComponent as HoveredFileLogo } from 'src/assets/svg/file-logo-filled.svg';

const brRegex = /<br>/g;

import Input from './components/Input';
import { RootState } from 'src/store';
import {
  FileResult,
  removeFileAttachment,
  setFileAttachments,
  setMessageInput,
} from 'src/store/reducers/conversation';
import FileAttachmentList from './components/FileAttachmentList';

import './InputBar.scoped.scss';

type InputBarProps = {
  buttonAlt: string;
  isAutoFocused: boolean;
  isInputDisabled: boolean;
  onSendMessage: () => void;
  onTextInputChange?: (event: any) => void;
  placeholder: string;
};

const InputBar = (
  {
    isAutoFocused,
    isInputDisabled,
    onSendMessage,
    onTextInputChange,
    placeholder,
  }: InputBarProps,
  ref: any
) => {
  const {
    conversation: { fileAttachments, fileUploadProgress, messageInput },
    settings: { isChatVisible },
  } = useSelector((state: RootState) => ({
    conversation: state.conversation,
    settings: state.settings,
  }));

  const inputRef = useRef<HTMLDivElement>(null!);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [enter, setEnter] = useState(false);
  const [firefox, setFirefox] = useState(false);
  const [height, setHeight] = useState(0);

  const [isFileButtonHovered, setFileButtonHovered] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isChatVisible && isAutoFocused) inputRef.current?.focus();
  }, [isAutoFocused, isChatVisible]);
  useEffect(() => {
    setFirefox(isFirefox());
  }, []);

  useImperativeHandle(ref, () => {
    return {
      onSelectEmoji: handlerOnSelectEmoji,
    };
  });

  const handlerOnChange = (event: any) => {
    dispatch(setMessageInput(event.target.textContent));
    onTextInputChange && onTextInputChange(event);
  };

  const handleOnSendMessage = () => {
    const el = inputRef.current;
    if (el.innerHTML) {
      el.innerHTML = '';
    }
    onSendMessage();
  };

  const handlerOnSelectEmoji = (emoji: { native: any }) => {
    const el = inputRef.current;
    const { start, end } = getSelection(el);
    if (el.innerHTML) {
      const firstPart = el.innerHTML.substring(0, start);
      const secondPart = el.innerHTML.substring(end);
      el.innerHTML = `${firstPart}${emoji.native}${secondPart}`;
    } else {
      el.innerHTML = emoji.native;
    }
    updateCaret(el, start, emoji.native.length);
  };

  const handleFileAttachment = (fileList: FileList | File[]) => {
    const fileListLength = fileList.length || 0;

    const addedFileList = [] as FileResult[];

    for (const targetFile of fileList) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', listener => {
        const fileIsViewable = !!targetFile.type.includes('image');
        const listenerTarget = listener.target as FileReader;

        const fileResult: FileResult = {
          name: targetFile.name,
          file: targetFile,
          canView: fileIsViewable,
          preview: listenerTarget.result as string,
        };
        addedFileList.push(fileResult);
        if (
          addedFileList.length === fileListLength &&
          fileAttachments.length === 0
        ) {
          dispatch(setFileAttachments(addedFileList));
        } else if (fileAttachments.length !== 0) {
          const newArr = [...fileAttachments, ...addedFileList];
          dispatch(setFileAttachments(newArr));
        }
      });
      fileReader.readAsDataURL(targetFile);
    }
  };

  const onChangeFileAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = e.target as HTMLInputElement;
    const fileList = eventTarget.files as FileList;
    handleFileAttachment(fileList);

    inputRef.current?.focus();
  };

  const onAddFileAttachment = () => {
    // `current` points to the mounted file input element
    inputFileRef.current?.click();
  };

  /**
   * A funtion utilized to remove a pending file attachment from the file attachment's list
   * @param file - a pending file to be sent
   * @returns void
   */
  const onRemoveFileAttachment = (file: FileResult) =>
    dispatch(removeFileAttachment(file));

  const handlerOnKeyPress = (event: {
    charCode: number;
    shiftKey: any;
    preventDefault: () => void;
  }) => {
    const el = inputRef.current;

    if (event.charCode == 13 && !event.shiftKey) {
      event.preventDefault();
      handleOnSendMessage();
    }
    if (event.charCode === 13 && event.shiftKey) {
      event.preventDefault();
      insertNodeAtCaret(el);
      setEnter(true);
    }
  };

  // TODO use a context for checkSize
  const checkSize = () => {
    const senderEl = refContainer.current;
    if (senderEl && height !== senderEl.clientHeight) {
      const { clientHeight } = senderEl;
      setHeight(clientHeight);
    }
  };

  const handlerOnKeyUp = (event: { key: string }) => {
    const el = inputRef.current;
    if (!el) return true;
    // Conditions need for firefox
    if (firefox && event.key === 'Backspace') {
      if (el.innerHTML.length === 1 && enter) {
        el.innerHTML = '';
        setEnter(false);
      } else if (brRegex.test(el.innerHTML)) {
        el.innerHTML = el.innerHTML.replace(brRegex, '');
      }
    }
    checkSize();
  };

  const handlerOnKeyDown = (event: {
    key: string;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const el = inputRef.current;

    if (event.key === 'Backspace' && el) {
      const caretPosition = getCaretIndex(inputRef.current);
      const character = el.innerHTML.charAt(caretPosition - 1);
      if (character === '\n') {
        event.preventDefault();
        event.stopPropagation();
        el.innerHTML =
          el.innerHTML.substring(0, caretPosition - 1) +
          el.innerHTML.substring(caretPosition);
        updateCaret(el, caretPosition, -1);
      }
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const fileList = acceptedFiles;
    handleFileAttachment(fileList);
  };

  const isSendButtonDisabled =
    messageInput.length || fileAttachments.length ? false : true;

  return (
    <>
      <FileAttachmentList
        fileAttachments={fileAttachments}
        fileUploadProgress={fileUploadProgress}
        onAddFileAttachment={onAddFileAttachment}
        onRemoveFileAttachment={onRemoveFileAttachment}
      />

      <Dropzone onDrop={onDrop} noClick>
        {({ getRootProps, getInputProps }) => (
          <div
            ref={refContainer}
            className="xqscw-input-bar-container"
            {...getRootProps}
          >
            <input {...getInputProps()} />
            <input
              type="file"
              id="file"
              multiple
              ref={inputFileRef}
              style={{ display: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeFileAttachment(e)
              }
            />

            <div id="input-bar">
              <Input
                handlerOnChange={handlerOnChange}
                handlerOnKeyDown={handlerOnKeyDown}
                handlerOnKeyPress={handlerOnKeyPress}
                handlerOnKeyUp={handlerOnKeyUp}
                inputRef={inputRef}
                isInputDisabled={isInputDisabled}
                placeholder={placeholder}
                containerStyle={{
                  cursor: 'text',
                  position: 'relative',
                  display: 'flex',
                }}
                inputStyle={{ paddingRight: 50, color: 'black' }}
              >
                <div
                  id="file-attachment-icon-container"
                  onClick={onAddFileAttachment}
                  onMouseEnter={() => {
                    setFileButtonHovered(true);
                  }}
                  onMouseLeave={() => {
                    setFileButtonHovered(false);
                  }}
                >
                  {isFileButtonHovered ? (
                    <HoveredFileLogo className="input-bar-icons" />
                  ) : (
                    <FileLogo className="input-bar-icons" />
                  )}
                </div>
              </Input>

              <div style={{ width: 35, height: 35 }}>
                {isSendButtonDisabled ? (
                  <SendButtonDisabledIcon onClick={handleOnSendMessage} />
                ) : (
                  <SendButtonIcon onClick={handleOnSendMessage} />
                )}
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
};

export default forwardRef(InputBar);
