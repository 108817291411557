import QuickButton from 'src/features/XQSCWidget/components/Conversation/components/QuickButtons/components/QuickButton';

import { MESSAGE_BOX_SCROLL_DURATION } from 'src/utils/constants';
import { baseMessageDocument, MessageDocument } from 'src/models/Message';
import { setTimestamp } from 'src/services/firebase';

export const createNewMessage = (
  _text: string,
  _sender: string,
  _id?: string
): MessageDocument => {
  return {
    ...baseMessageDocument,
    date: setTimestamp(),
    expirationHours: 24,
  };
};

export const createQuickButton = (button: {
  label: string;
  value: string | number;
}) => {
  return {
    component: QuickButton,
    label: button.label,
    value: button.value,
  };
};

const sinEaseOut = (
  timestamp: any,
  begining: any,
  change: any,
  duration: any
) => {
  return (
    change *
      ((timestamp = timestamp / duration - 1) * timestamp * timestamp + 1) +
    begining
  );
};

/**
 *
 * @param {*} target scroll target
 * @param {*} scrollStart
 * @param {*} scroll scroll distance
 */
const scrollWithSlowMotion = (
  target: any,
  scrollStart: any,
  scroll: number
) => {
  const raf = window?.requestAnimationFrame;
  let start = 0;
  const step = (timestamp: any) => {
    if (!start) {
      start = timestamp;
    }
    const stepScroll = sinEaseOut(
      timestamp - start,
      0,
      scroll,
      MESSAGE_BOX_SCROLL_DURATION
    );
    const total = scrollStart + stepScroll;
    target.scrollTop = total;
    if (total < scrollStart + scroll) {
      raf(step);
    }
  };
  raf(step);
};

export const scrollToBottom = (messagesDiv: HTMLDivElement | null) => {
  if (!messagesDiv) return;
  const screenHeight = messagesDiv.clientHeight;
  const scrollTop = messagesDiv.scrollTop;
  const scrollOffset = messagesDiv.scrollHeight - (scrollTop + screenHeight);
  if (scrollOffset) scrollWithSlowMotion(messagesDiv, scrollTop, scrollOffset);
};
