export interface UserSettings {
  isAliasUser: boolean;
  notificationsEnabled: boolean;
}

type UserSelectedOptions = 'conversationId' | 'workspaceId';

export enum UserModelFields {
  AVATAR = 'avatar',
  CREATED_AT = 'createdAt',
  EMAIL = 'email',
  ID = 'id',
  IS_ACTIVE = 'isActive',
  PHONE = 'phone',
  NAME = 'name',
  SELECTED = 'selected',
  SETTINGS = 'settings',
  WORKSPACES = 'workspaces',
}

export interface User {
  [UserModelFields.AVATAR]: string;
  [UserModelFields.CREATED_AT]: Date | null;
  [UserModelFields.EMAIL]: string;
  [UserModelFields.ID]: string;
  [UserModelFields.IS_ACTIVE]: boolean;
  [UserModelFields.PHONE]: string;
  [UserModelFields.NAME]: string;
  [UserModelFields.SELECTED]: {
    [setting in UserSelectedOptions]: string;
  };
  [UserModelFields.SETTINGS]: UserSettings;
  [UserModelFields.WORKSPACES]: {
    [workspaceId: string]: boolean;
  };
}

export const baseUserDocument: User = {
  [UserModelFields.AVATAR]: '',
  [UserModelFields.CREATED_AT]: null,
  [UserModelFields.EMAIL]: '',
  [UserModelFields.ID]: '',
  [UserModelFields.IS_ACTIVE]: true,
  [UserModelFields.PHONE]: '',
  [UserModelFields.NAME]: '',
  [UserModelFields.SELECTED]: {
    conversationId: '',
    workspaceId: '',
  },
  [UserModelFields.SETTINGS]: {
    isAliasUser: true,
    notificationsEnabled: true,
  },
  [UserModelFields.WORKSPACES]: {},
};
