import React, { useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';

// import markdownIt from 'markdown-it';
// const markdownItSanitizer = require('markdown-it-sanitizer');
// const markdownItClass = require('@toycode/markdown-it-class');
// import markdownItLinkAttributes from 'markdown-it-link-attributes';

import { ReactComponent as LoadingDotsIcon } from '../../../../../../../../assets/svg/loading-dots.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../../../assets/svg/error-icon.svg';

import './Message.scoped.scss';

import {
  MessageDocument,
  MessageState,
  ProcessedMessage,
} from 'src/models/Message';
import OptionMenu from '../OptionMenu';
import { ColorTheme } from 'src/features/XQSCWidget/types/Branding';
import FileAttachment from '../FileAttachment';

export const MSG_ID_PREFIX = 'msgid_';

type MessageProps = {
  isCurrentUser: boolean;
  index: number;
  isLoading: boolean;
  isTimestampVisible: boolean;
  message: MessageDocument;
  onImageClick: (imgSrc: string) => void;
  onDeleteMessage: (message: ProcessedMessage) => void;
  theme: ColorTheme;
};

const Message: React.FC<MessageProps> = ({
  isCurrentUser,
  index,
  isLoading,
  isTimestampVisible,
  message,
  onImageClick,
  onDeleteMessage,
  theme,
}) => {
  const container = document.getElementById(MSG_ID_PREFIX + message.id);
  const containerRef = useRef<HTMLDivElement>(null);

  const [hoveredMessageId, setHoveredMessageId] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState(false);

  const onHover = (hoveredMessage: MessageDocument | '') => {
    if (container) {
      container.style.zIndex = hoveredMessage === '' ? '10' : '200';
    }

    return hoveredMessage
      ? setHoveredMessageId(hoveredMessage.id)
      : setHoveredMessageId('');
  };

  /**
   * A function utilized to copy text from a message bubble
   */
  const onCopyMessageText = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(message.text);
    } else {
      return document.execCommand('copy', true, message.text);
    }
  };

  /**
   * A function utilized to download a decrypted file attachment
   * @param fileReferenceUrl - url of the fileAttachment
   * @param name - name of the fileAttachment
   */
  const onDownloadFile = async (fileReferenceUrl: string, name: string) => {
    const link = document.createElement('a');
    link.download = name;
    link.target = '_blank';
    link.href = fileReferenceUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getMessageText = useMemo(() => {
    switch (message.state) {
      case MessageState.FAILED_ENCRYPTION:
        return 'Failed to encrypt this message';
      case MessageState.FAILED_DECRYPTION:
        return 'Failed to decrypt this message';
      case MessageState.DELETED:
        return 'This message has been deleted';
      default:
        return message.text;
    }
  }, [message.state, message.text]);

  // TODO(worstestes - 4.27.22): determine if it's worth it to retain markdownit
  // const sanitizedHTML = markdownIt({ breaks: true, linkify: true })
  //   .use(markdownItClass, {
  //     img: ['xqscw-message-img'],
  //   })
  //   .use(markdownItSanitizer)
  //   .use(markdownItLinkAttributes, {
  //     attrs: { target: '_blank', rel: 'noopener' },
  //   })
  //   .render(messageText ?? '');

  const renderMessageContent = () => {
    if (isLoading) {
      return (
        <div style={{ padding: 5 }}>
          <LoadingDotsIcon />
        </div>
      );
    }

    return (
      <div className="xqscw-message-content" key={index}>
        {(message.state === MessageState.DELETED ||
          message.state === MessageState.FAILED_ENCRYPTION ||
          message.state === MessageState.FAILED_DECRYPTION) && (
          <ErrorIcon
            style={{
              height: 14,
              marginRight: 5,
            }}
          />
        )}
        {message &&
          message.fileAttachment?.url &&
          message.state === MessageState.ACTIVE && (
            <FileAttachment onImageClick={onImageClick} message={message} />
          )}
        <span
          className="xqscw-message-text"
          // dangerouslySetInnerHTML={{ __html: sanitizedHTML.replace(/\n$/, '') }}
          style={{
            color: isCurrentUser
              ? theme.primaryTextColor
              : theme.secondaryTextColor,
          }}
        >
          {getMessageText}
        </span>
      </div>
    );
  };

  return (
    <div
      className={`xqscw-message ${
        isCurrentUser ? 'xqscw-message-current-user' : ''
      }`}
      key={`${index}-${format(message.date.toDate(), 'hh:mm')}`}
    >
      <div
        className={`${
          isCurrentUser ? 'xqscw-current-user' : 'xqscw-recipient'
        } ${!isLoading && 'animated animatedFadeInUp fadeInUp'} ${
          message.state !== MessageState.ACTIVE && !isLoading
            ? 'message-error'
            : ''
        }`}
        id={MSG_ID_PREFIX + message.id}
        ref={containerRef}
        onMouseEnter={() => onHover(message)}
        onMouseLeave={() => {
          onHover('');
          setShowDropdown(false);
        }}
        style={{
          backgroundColor: isCurrentUser
            ? theme.primaryThemeColor
            : theme.secondaryThemeColor,
          color: isCurrentUser
            ? theme.primaryTextColor
            : theme.secondaryTextColor,
        }}
      >
        {renderMessageContent()}
        <OptionMenu
          isCurrentUser={isCurrentUser}
          container={containerRef}
          isVisible={
            message.id === hoveredMessageId &&
            message.state == MessageState.ACTIVE &&
            !isLoading
          }
          showDropdown={showDropdown}
          onSetShowDropdown={() => setShowDropdown(!showDropdown)}
          onCopyMessageText={
            message?.fileAttachment?.url ? undefined : onCopyMessageText
          }
          onDeleteMessage={() => onDeleteMessage(message as ProcessedMessage)}
          onDownloadFile={
            message?.fileAttachment?.url
              ? () =>
                  onDownloadFile(
                    message.fileAttachment.url,
                    message.fileAttachment.name
                  )
              : undefined
          }
        />
        {isTimestampVisible && !isLoading && (
          <span
            className="xqscw-timestamp"
            style={{
              color: isCurrentUser
                ? theme.primaryTextColor
                : theme.secondaryTextColor,
            }}
          >
            {format(message.date.toDate(), 'hh:mm')}
          </span>
        )}
      </div>
    </div>
  );
};

export default Message;
