import { useSelector } from 'react-redux';
import cn from 'classnames';

import NotificationBadge from './components/NotificationBadge';

import './Launcher.scoped.scss';

import { ReactComponent as MessageBubbleIcon } from 'src/assets/svg/message-bubble-icon.svg';
import { ReactComponent as ChevronDownIcon } from 'src/assets/svg/chevron-down.svg';

import { RootState } from 'src/store';
import { useDetectMobileDevice } from 'src/hooks/useDeviceType';

type LauceherProps = {
  onToggle: () => void;
  openLabel: string;
  openImg: string;
  isNotificationBadgeVisible?: boolean;
};

const Launcher = ({ onToggle }: LauceherProps) => {
  const {
    settings: {
      isChatVisible,
      branding: { theme },
    },
    notificationCount,
  } = useSelector((state: RootState) => ({
    settings: state.settings,
    notificationCount: state.conversation.notificationCount,
  }));

  const isMobile = useDetectMobileDevice();

  if (isChatVisible && isMobile) {
    return null;
  }

  return (
    <div
      className={cn('xqscw-launcher')}
      style={{ backgroundColor: theme.primaryThemeColor }}
      onClick={onToggle}
    >
      {!isChatVisible && notificationCount > 0 && (
        <NotificationBadge badge={notificationCount} />
      )}
      {!isChatVisible && (
        <MessageBubbleIcon
          id="launcher-open-icon"
          fill={theme.primaryTextColor}
        />
      )}
      {isChatVisible && (
        <ChevronDownIcon
          id="launcher-close-icon"
          stroke={theme.primaryTextColor}
        />
      )}
    </div>
  );
};

export default Launcher;
