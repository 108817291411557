import {
  AuthorizeAlias,
  CommunicationsEnum,
  Decrypt,
  Encrypt,
  EncryptionAlgorithm,
  FileDecrypt,
  FileEncrypt,
  GetWorkspaces,
  RevokeKeyAccess,
  ServerResponse,
  XQSDK,
} from '@xqmsg/jssdk-core';
import { UserStatus } from '@xqmsg/jssdk-core/dist/types/src/com/xqmsg/sdk/v2/types/dashboard';
import {
  baseMessageDocument,
  DecryptedMessagePayload,
  EncryptedMessageDocument,
  MessageState,
} from 'src/models/Message';
import { initializedXQSDK } from './chat';

/** The default metadata subject field used for event logs */
const DEFAULT_METADATA_SUBJECT_FIELD = 'Secure Chat Message';

/**
 * A function utilized to authorize a user via the XQ `AuthorizeAlias` service
 * @param email - the user's email
 * @param onSuccess - the onSuccess callback when `ServerResponse.OK` is returned from XQ SDK
 * @param onFail - the onFail callback when `ServerResponse.ERROR` is returned from XQ SDK
 * @returns void
 */
export const authorizeAliasUserViaXQ = async (
  email: string,
  onSuccess: (email: string) => void,
  onFail: (error: string) => void
) => {
  await new AuthorizeAlias(initializedXQSDK)
    .supplyAsync({
      [AuthorizeAlias.USER]: email,
    })
    .then(response => {
      switch (response.status) {
        case ServerResponse.OK: {
          onSuccess(email);
          break;
        }
        case ServerResponse.ERROR: {
          onFail(`Unable to authorize user: ${email}`);
          break;
        }
      }
    });
};

/**
 * A function utilized to encrypt text via the XQ `Encrypt` service
 * @param message - the message string
 * @param conversationId - the id for the current conversation
 * @param messageId - the id for the message
 * @param recipients - an array of recipient email strings
 * @param sdk - the XQ SDK instance
 * @param algorithm - the specified encryption algorithm to encrypt with
 * @param onSuccess - the onSuccess callback when `ServerResponse.OK` is returned from XQ SDK
 * @param onFail - the onFail callback when `ServerResponse.ERROR` is returned from XQ SDK
 * @returns Promise<{ encryptedText: string; locatorToken: string; }>
 */
export const encryptMessage = async (
  message: string,
  conversationId: string,
  messageId: string,
  recipients: string[],
  sdk: XQSDK,
  algorithm: EncryptionAlgorithm,
  onFail?: () => void
) => {
  const payload = {
    [Encrypt.TEXT]: message,
    [Encrypt.RECIPIENTS]: recipients,
    [Encrypt.EXPIRES_HOURS]: 24,
    [Encrypt.TYPE]: CommunicationsEnum.CHAT,
    [Encrypt.META]: {
      subject: DEFAULT_METADATA_SUBJECT_FIELD,
      conversationId,
      messageId,
    },
  };

  const response = await new Encrypt(sdk, algorithm).supplyAsync(payload);
  switch (response?.status) {
    case ServerResponse.OK: {
      const data = response.payload;
      const locatorToken: string = data[Encrypt.LOCATOR_KEY];
      const encryptedText: string = data[Encrypt.ENCRYPTED_TEXT];
      return { encryptedText, locatorToken };
    }
    case ServerResponse.ERROR: {
      if (onFail) {
        onFail();
      }
      break;
    }
  }
  return { encryptedText: '', locatorToken: '' };
};

/**
 * A function utilized to encrypt text via the XQ `Decrypt` service
 * @param message - the encrypted message document from the firebase `messages` sub-collection
 * @param sdk - the XQ SDK instance
 * @param algorithm - the specified encryption algorithm to encrypt with
 */
export const decryptMessage = async (
  message: EncryptedMessageDocument,
  sdk: XQSDK,
  algorithm: EncryptionAlgorithm,
  onFail?: () => void
) => {
  const decryptResponse = await new Decrypt(sdk, algorithm).supplyAsync({
    [Decrypt.ENCRYPTED_TEXT]: message.payload,
    [Decrypt.LOCATOR_KEY]: message.locatorToken,
  });

  const baseDecryptedMessagePayload: DecryptedMessagePayload = {
    expirationHours: baseMessageDocument.expirationHours,
    fileAttachment: baseMessageDocument.fileAttachment,
    text: baseMessageDocument.text,
    urlAttachments: baseMessageDocument.urlAttachments,
  };

  if (message.state === MessageState.DELETED) {
    return {
      ...baseDecryptedMessagePayload,
      text: 'This message has been deleted',
      status: 410,
    };
  }

  switch (decryptResponse?.status) {
    case ServerResponse.OK: {
      const data = decryptResponse.payload;

      const decryptedPayload = JSON.parse(
        data[EncryptionAlgorithm.DECRYPTED_TEXT]
      );

      return decryptedPayload;
    }
    case ServerResponse.ERROR: {
      if (onFail) {
        onFail();
      }

      break;
    }
  }

  return { text: 'failed' };
};

/**
 * Revokes key access of a specified locator key.
 * Only the user who sent the message will be able to revoke it.
 *
 * @param locatorToken - a locator key string value
 */
export const revokeKeyAccess = async (locatorKey: string) => {
  const revokeKeyResponse = await new RevokeKeyAccess(
    initializedXQSDK
  ).supplyAsync({
    locatorKeys: [locatorKey],
  });

  return revokeKeyResponse;
};

/**
 * A function utilized to encrypt a given file via the XQ `FileEncrypt` service
 *
 * @param sourceFile - a File to encrypt
 * @param recipients - an array of recipient email strings
 */
export const encryptFile = async (sourceFile: File, recipients: string[]) => {
  const OTPv2Algorithm = initializedXQSDK.getAlgorithm('OTPv2');

  const encryptFileResponse = await new FileEncrypt(
    initializedXQSDK,
    OTPv2Algorithm
  ).supplyAsync({
    [FileEncrypt.DELETE_ON_RECEIPT]: false,
    [FileEncrypt.EXPIRES_HOURS]: 24,
    [FileEncrypt.RECIPIENTS]: recipients,
    [FileEncrypt.SOURCE_FILE]: sourceFile,
  });

  switch (encryptFileResponse?.status) {
    case ServerResponse.OK: {
      const encryptedFile = encryptFileResponse.payload as File;
      return encryptedFile;
    }
    case ServerResponse.ERROR: {
      return encryptFileResponse;
    }
  }
  return encryptFileResponse;
};

export const decryptFile = async (sourceFile: File) => {
  const OTPv2Algorithm = initializedXQSDK.getAlgorithm('OTPv2');

  const decryptFileResponse = await new FileDecrypt(
    initializedXQSDK,
    OTPv2Algorithm
  ).supplyAsync({
    [FileDecrypt.SOURCE_FILE]: sourceFile,
  });

  switch (decryptFileResponse?.status) {
    case ServerResponse.OK: {
      const decryptedFile = decryptFileResponse.payload as File;

      // TODO(worstestes - 3.15.22): replace this after SDK fix
      const TEMP_DECRYPTED_FILE = new File(
        [decryptedFile],
        sourceFile.name.slice(0, -4)
      );

      return TEMP_DECRYPTED_FILE;
    }
    case ServerResponse.ERROR: {
      return decryptFileResponse;
    }
  }
  return decryptFileResponse;
};

export const getWorkspaces = async (email: string) => {
  const getWorkspacesResponse = await new GetWorkspaces(
    initializedXQSDK
  ).supplyAsync({
    [GetWorkspaces.EMAIL]: email,
  });

  switch (getWorkspacesResponse?.status) {
    case ServerResponse.OK: {
      const workspaceList: { [key: string]: boolean } = {};
      const { workspaces } = getWorkspacesResponse.payload;

      workspaces.forEach(
        (workspace: { bid: number; state: string }) =>
          (workspaceList[workspace.bid] = workspace.state === 'Validated')
      );

      return workspaceList;
    }
    case ServerResponse.ERROR: {
      return getWorkspacesResponse;
    }
  }
  return getWorkspacesResponse;
};
