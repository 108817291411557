import { useEffect, useRef } from 'react';

import { scrollToBottom } from 'src/utils/messages';

import './Body.scoped.scss';
import MessageList from './components/MessageList';
import { User } from 'src/models/User';
import { EncryptedMessageDocument, ProcessedMessage } from 'src/models/Message';
import { ColorTheme } from 'src/features/XQSCWidget/types/Branding';

type BodyProps = {
  currentUser: User;
  isMessageLoaderToggled: boolean;
  isOnboarded: boolean;
  isSendingMessage: boolean;
  messageLoaders: EncryptedMessageDocument[];
  messages: ProcessedMessage[];
  onImageClick: (imgSrc: string) => void;
  onDeleteMessage: (message: ProcessedMessage) => void;
  theme: ColorTheme;
};

const Body = ({
  currentUser,
  isMessageLoaderToggled,
  isSendingMessage,
  messageLoaders,
  messages,
  onImageClick,
  onDeleteMessage,
  theme,
}: BodyProps) => {
  const bodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom(bodyRef.current);

    setTimeout(() => {
      scrollToBottom(bodyRef.current);
    }, 1000);
  }, [bodyRef, messages, messageLoaders, isSendingMessage]);

  return (
    <div id="messages" className="xqscw-messages-container" ref={bodyRef}>
      <MessageList
        currentUser={currentUser}
        isMessageLoaderToggled={isMessageLoaderToggled}
        messageLoaders={messageLoaders}
        messages={messages}
        onImageClick={onImageClick}
        onDeleteMessage={onDeleteMessage}
        theme={theme}
      />
    </div>
  );
};

export default Body;
