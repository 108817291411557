import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Provider } from 'react-redux';

import './scss/Root.scoped.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'src/features/XQSCWidget/main';

import store from 'src/store';

const body = document.querySelector('body');

const widgetRoot = document.createElement('div');
widgetRoot.setAttribute('id', 'widget-root');

body?.appendChild(widgetRoot);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('widget-root')
);
