import { XQSDK } from '@xqmsg/jssdk-core';
import { Dispatch } from 'redux';

import {
  baseConversationDocument,
  ConversationDocument,
  InvitesField,
  isHiddenField,
  IsTypingField,
  NotificationsField,
  ProcessedConversation,
} from 'src/models/Conversation';

import {
  baseMessageDocument,
  baseAutomatedMessage,
  DecryptedMessagePayload,
  EncryptedMessageDocument,
  MessageState,
  ProcessedMessage,
  FileAttachment,
} from 'src/models/Message';

import { User, UserModelFields } from 'src/models/User';

import {
  decryptFile,
  decryptMessage,
  encryptMessage,
  getWorkspaces,
  revokeKeyAccess,
} from 'src/services/XQSDK';
import store from 'src/store';
import {
  addMessage,
  addMessageLoader,
  removeMessageLoader,
} from 'src/store/reducers/conversation';

import {
  AvailableCollections,
  FirebaseTimestamp,
  fetchUser,
  firestoreOperations,
  initializedFirebaseClient,
  setTimestamp,
  fetchConversation,
  setExpiresAtTimestamp,
  fetchUserByEmail,
} from './firebase';

const {
  getFirestore,
  collection,
  setDoc,
  doc,
  onSnapshot,
  query,
  orderBy,
  limit,
} = firestoreOperations;

const script_tag = document.getElementById('xq-widget-embed');
const XQ_API_KEY =
  script_tag?.getAttribute('data-xq-subscription-key') ||
  process.env.REACT_APP_SUBSCRIPTION_API_KEY;

export const initializedXQSDK = new XQSDK(
  {
    XQ_API_KEY: XQ_API_KEY ?? '',
    DASHBOARD_API_KEY: process.env.REACT_APP_DASHBOARD_API_KEY ?? '',
  },
  {
    DASHBOARD_SERVER_URL: process.env.REACT_APP_DASHBOARD_HOST,
    KEY_SERVER_URL: process.env.REACT_APP_QUANTUM_HOST,
    SUBSCRIPTION_SERVER_URL: process.env.REACT_APP_SUBSCRIPTION_HOST,
    VALIDATION_SERVER_URL: process.env.REACT_APP_VALIDATION_HOST,
  }
);

const FIRESTORE = getFirestore(initializedFirebaseClient);

/**
 * A function utilized to stream messages from a given conversation
 *
 * @param conversationId - ID of the current conversation to stream messages from
 * @param callback - the callback function
 * @returns void
 */
export const streamConversationMessages = (
  conversationId: string,
  callback: (encryptedMessageDocument: EncryptedMessageDocument) => void
) => {
  return onSnapshot(
    query(
      collection(
        FIRESTORE,
        AvailableCollections.CONVERSATIONS,
        conversationId,
        AvailableCollections.MESSAGES
      ),
      orderBy('date', 'desc'),
      limit(1)
    ),
    conversationMessagesSnapshot => {
      return conversationMessagesSnapshot.docs.map(async doc => {
        const encryptedMessageDocument = doc.data() as EncryptedMessageDocument;
        return callback(encryptedMessageDocument);
      });
    }
  );
};

/**
 * A function utilized to process and format a `ConversationDocument` from Firestore.
 * This process includes fetching recipient data associated with the conversation
 * @param conversationDocument - a `ConversationDocument` from Firestore
 * @returns `Promise<ProcessedConversation>`
 */
const processConversation = async (
  conversationDocument: ConversationDocument
): Promise<ProcessedConversation> => {
  const processedRecipientsList = await Promise.all(
    conversationDocument.recipients.map(async id => {
      const fetchedUser = await fetchUser(id);
      const userAvatar = '';

      return {
        ...fetchedUser,
        avatar: userAvatar,
      };
    })
  );

  return {
    ...conversationDocument,
    recipients: processedRecipientsList,
  };
};

export const createAutomatedMessage = async (automatedMessage: string) => {
  const messageDocument = {
    ...baseAutomatedMessage,
    text: automatedMessage,
    senderId: 'auto',
    state: MessageState.LOADING,
  };

  const stubbedMessageDocumentForLoader = (messageDocument as unknown) as EncryptedMessageDocument;

  store.dispatch(addMessageLoader(stubbedMessageDocumentForLoader));

  setTimeout(() => {
    store.dispatch(
      addMessage({
        ...stubbedMessageDocumentForLoader,
        state: MessageState.ACTIVE,
      })
    );
    store.dispatch(removeMessageLoader(stubbedMessageDocumentForLoader));
  }, 750);
};

export const createConversation = async (
  currentUser: User,
  supportTeamUserEmails: string[],
  workspaceId?: number
) => {
  const supportTeamUserIds: string[] = [];

  for await (const email of supportTeamUserEmails) {
    const user = await fetchUserByEmail(email);
    const workspaces = (await getWorkspaces(email)) as {
      [key: string]: boolean;
    };

    const userBelongsToWorkspace =
      workspaceId && workspaces[workspaceId?.toString()];

    if (user && (!workspaceId || userBelongsToWorkspace)) {
      supportTeamUserIds.push(user.id);
    }
  }

  const recipientIds = [currentUser.id, ...supportTeamUserIds];

  const invites: InvitesField = {};
  const isHidden: isHiddenField = {};
  const isTyping: IsTypingField = {};
  const notifications: NotificationsField = {};

  recipientIds.forEach(id => {
    invites[id] = false;
    isHidden[id] = { date: null, now: false };
    isTyping[id] = false;
    notifications[id] = 0;
  });

  // we sort these and add them to the `ConversationDocument` in order to perform shallow comparison for querying
  const sortedRecipientIds = recipientIds.slice(0).sort();

  const newConversationId = doc(
    collection(FIRESTORE, AvailableCollections.CONVERSATIONS)
  ).id;

  const newConversationDocument: ConversationDocument = {
    ...baseConversationDocument,
    createdAt: setTimestamp(),
    id: newConversationId,
    invites,
    isHidden,
    isTyping,
    notifications,
    recipients: recipientIds,
    sortedRecipients: sortedRecipientIds,
    expiresAt: setExpiresAtTimestamp(24),
    workspaceId: '0',
  };

  const _addNewConversationDocument = await setDoc(
    doc(
      FIRESTORE,
      AvailableCollections.CONVERSATIONS,
      newConversationDocument.id
    ),
    newConversationDocument
  ).catch(e => console.log(e));

  return processConversation(newConversationDocument);
};

export const updateConversation = async (
  currentUser: User,
  currentConversation: ProcessedConversation,
  newMessageDate: FirebaseTimestamp
) => {
  // TODO(worstestes - 2.13.21): not really efficient to fetch a document everytime we send a message. Figure out better way to handle notification increments later
  const currentConversationDocument = await fetchConversation(
    currentConversation.id
  );

  const updatedIsHidden: isHiddenField = {};
  const updatedNotifications: NotificationsField = {};
  currentConversation.recipients.forEach(recipient => {
    // update `isHidden` field for conversation
    if (currentConversation.isHidden[recipient.id]) {
      updatedIsHidden[recipient.id] = {
        date: currentConversation.isHidden[recipient.id].date,
        now: false,
      };
    }
    if (recipient.id === currentUser.id) {
      updatedNotifications[currentUser.id] = 0;
    } else {
      updatedNotifications[recipient.id] =
        currentConversationDocument.notifications[recipient.id] + 1;
    }
  });

  const _updateConversationFields = await setDoc(
    doc(FIRESTORE, AvailableCollections.CONVERSATIONS, currentConversation.id),
    {
      updatedAt: newMessageDate,
      isHidden: updatedIsHidden,
      notifications: updatedNotifications,
    },
    { merge: true }
  );
};
/**
 * A function utilized to delete a message via ID of a given `Conversation`
 * @param conversationId - the ID of the conversation
 * @param messageId - the ID of the message
 * @returns void
 */
export const deleteMessage = async (
  conversationId: string,
  messageId: string,
  locatorToken: string,
  fileReferenceUrl?: string
) => {
  const resetMessageDataFields = {
    payload: '',
    state: MessageState.DELETED,
  };

  const _updateConversationDocument = await setDoc(
    doc(
      FIRESTORE,
      AvailableCollections.CONVERSATIONS,
      conversationId,
      AvailableCollections.MESSAGES,
      messageId
    ),
    { ...resetMessageDataFields },
    { merge: true }
  );

  if (fileReferenceUrl) {
    window.URL.revokeObjectURL(fileReferenceUrl);
  }

  return revokeKeyAccess(locatorToken);
};

export const createMessage = async (
  currentConversation: ProcessedConversation,
  currentUser: User,
  messagePayload: {
    messageInput: string;
    fileAttachment?: FileAttachment;
  },
  dispatch: Dispatch
) => {
  const newMessageDocumentId = doc(
    collection(FIRESTORE, AvailableCollections.MESSAGES)
  ).id;

  const newMessageDate = setTimestamp();

  let newMessageDocument: EncryptedMessageDocument = {
    date: newMessageDate,
    id: newMessageDocumentId,
    locatorToken: '',
    payload: '',
    senderId: currentUser.id,
    state: MessageState.LOADING,
  };

  const updatedCurrentConversation = currentConversation;

  const recipientEmails = updatedCurrentConversation.recipients.map(recipient =>
    recipient.settings.isAliasUser
      ? recipient.email + '@alias.local'
      : recipient.email
  );
  const messageExpiryTime =
    currentConversation.messageExpiryTimeList[
      currentConversation.messageExpiryTimeList.length - 1
    ].value;

  const algorithm = initializedXQSDK.getAlgorithm('OTPv2');

  const decryptedMessagePayload: DecryptedMessagePayload = {
    fileAttachment:
      messagePayload.fileAttachment || baseMessageDocument.fileAttachment,
    text: messagePayload.messageInput,
    expirationHours: messageExpiryTime,
    urlAttachments: baseMessageDocument.urlAttachments,
  };

  const readableProcessedMessage = {
    ...newMessageDocument,
    status: 200,
    ...decryptedMessagePayload,
    sender: {
      avatar: currentUser[UserModelFields.AVATAR],
      id: currentUser[UserModelFields.ID],
      name: currentUser[UserModelFields.NAME],
    },
    state: MessageState.ACTIVE,
  };

  // Show loading dots if file attachment - getting preview takes too long
  if (messagePayload.fileAttachment) {
    dispatch(addMessageLoader(newMessageDocument));
  } else {
    dispatch(addMessage(readableProcessedMessage));
  }

  let newMessageDocumentMessageState: MessageState = MessageState.ACTIVE;

  const onFail = () =>
    (newMessageDocumentMessageState = MessageState.FAILED_ENCRYPTION);

  const encryptedMessagePayload = await encryptMessage(
    JSON.stringify(decryptedMessagePayload),
    updatedCurrentConversation.id,
    newMessageDocumentId,
    recipientEmails,
    initializedXQSDK,
    algorithm,
    onFail
  );

  newMessageDocument = {
    ...newMessageDocument,
    date: newMessageDate,
    id: newMessageDocumentId,
    locatorToken: encryptedMessagePayload.locatorToken,
    payload: encryptedMessagePayload.encryptedText,
    senderId: currentUser.id,
    state: newMessageDocumentMessageState,
  };

  const _addNewConversationDocument = await setDoc(
    doc(
      FIRESTORE,
      AvailableCollections.CONVERSATIONS,
      updatedCurrentConversation.id,
      AvailableCollections.MESSAGES,
      newMessageDocument.id
    ),
    newMessageDocument
  );

  await updateConversation(
    currentUser,
    currentConversation,
    newMessageDocument.date
  );
};

/**
 * A function utilized to process and format a `MessageDocument` from Firestore.
 * This process includes fetching sender data amd decrypting text or files of the `MessageDocument`
 * @param message - a `MessageDocument` from Firestore
 * @returns `ProcessedMessage`
 */
export const processMessage = async (
  message: EncryptedMessageDocument
): Promise<Partial<ProcessedMessage>> => {
  const senderUserData: User = await fetchUser(message.senderId);

  const senderName = senderUserData.name
    ? senderUserData.name
    : senderUserData.email;

  if (message.state === MessageState.FAILED_ENCRYPTION) {
    return {
      ...message,
      text: 'Failed to encrypt this message',
      isExpired: false,
      status: 200,
      sender: {
        avatar: senderUserData.avatar,
        id: senderUserData.id,
        name: senderName,
      },
    };
  }

  const algorithm = initializedXQSDK.getAlgorithm('OTPv2');

  let messageState: MessageState = message.state;

  const onFail = () => (messageState = MessageState.FAILED_DECRYPTION);

  const decryptedPayload = await decryptMessage(
    message,
    initializedXQSDK,
    algorithm,
    onFail
  );

  const decryptedFileAttachment = decryptedPayload.fileAttachment.url
    ? await decryptFileAttachment(decryptedPayload.fileAttachment)
    : baseMessageDocument.fileAttachment;

  return {
    ...message,
    state: messageState,
    isExpired: false,
    status: 200,
    ...decryptedPayload,
    ...decryptedFileAttachment,
    date: message.date,
    sender: {
      avatar: senderUserData.avatar,
      id: senderUserData.id,
      name: senderName,
    },
  };
};

/**
 * A function utilized to decrypt a file attachment of a message
 * @param fileAttachment - `FileAttachment`
 * @returns - { name: string, type: string; url: string; size: number; }
 */
const decryptFileAttachment = async (fileAttachment: FileAttachment) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let fileDecryptionError: any = {};

  try {
    const decryptedFileBlob = await fetch(fileAttachment.url).then(response =>
      response.blob()
    );

    const blobToFile = new File([decryptedFileBlob], fileAttachment.name);

    const decryptedFile = (await decryptFile(blobToFile)) as File;

    const decryptedFileUrl =
      decryptedFileBlob && URL.createObjectURL(decryptedFile);

    return {
      fileAttachment: {
        name: decryptedFile.name,
        type: fileAttachment.type,
        url: decryptedFileUrl,
        size: decryptedFileBlob && decryptedFile.size,
      },
    };
  } catch (error) {
    fileDecryptionError = generateErrorMessage(error);
  }

  // if there was a file decryption error we pass along the generated error message and also ensure
  // the file name is wiped from the user's view since it was not decrypted succesfully
  if (fileDecryptionError.status) {
    return {
      ...fileDecryptionError,
      fileAttachment: {
        name: '',
      },
    };
  }
};

type ValidationErrorStatusCode = 400 | 404 | 410 | 500;

const ValidationErrorMessage: {
  [index in ValidationErrorStatusCode]: string;
} = {
  400: 'There was an issue decrypting this message',
  404: 'There was an issue decrypting this message',
  410: 'This message has been deleted',
  500: 'There was an issue communicating with the server',
};

const generateErrorMessage = (error: any) => {
  const status = error.response?.status as ValidationErrorStatusCode;

  const errorMessage =
    ValidationErrorMessage[status] || ValidationErrorMessage[400];

  return {
    status: status || 400,
    text: errorMessage,
    fileAttachment: baseMessageDocument.fileAttachment,
  };
};
