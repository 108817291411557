import React from 'react';

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/close-icon.svg';

import './CloseButton.scoped.scss';

interface CloseButtonProps {
  onClick?: () => void;
  stroke?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, stroke }) => (
  <CloseIcon
    onClick={onClick}
    className="close-button"
    style={{ stroke: stroke }}
  />
);

export default CloseButton;
