import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import { AnyFunction } from 'src/utils/types';
import {
  openImagePreview,
  setCurrentImagePreview,
  setNotificationCount,
} from 'src/store/reducers/conversation';

import Conversation from './components/Conversation';
import Launcher from './components/Launcher';
import ImagePreview from './components/ImagePreview';

import './Layout.scss';
import { ProcessedMessage } from 'src/models/Message';
import { RootState } from 'src/store';

type WidgetLayoutProps = {
  customLauncher?: AnyFunction;
  emojis?: boolean;
  isAutoFocused: boolean;
  isImagePreviewMode: boolean;
  isNotificationBadgeVisible?: boolean;
  isResizeable?: boolean;
  launcherOpenImg: string;
  launcherOpenLabel: string;
  onDeleteMessage: (message: ProcessedMessage) => void;
  onQuickButtonClicked: AnyFunction;
  onSendMessage: () => void;
  onSetCurrentUser: (name?: string, email?: string) => void;
  onSubmitOfflineForm: (name: string, email: string, message: string) => void;
  onTextInputChange?: (event: any) => void;
  onToggleWidgetVisibility: AnyFunction;
  sendButtonAlt: string;
  senderPlaceHolder: string;
  subtitle: string;
  titleAvatar?: string;
};

const WidgetLayout = ({
  customLauncher,
  emojis,
  isAutoFocused,
  isNotificationBadgeVisible,
  isResizeable,
  launcherOpenImg,
  launcherOpenLabel,
  onDeleteMessage,
  onQuickButtonClicked,
  onSendMessage,
  onSetCurrentUser,
  onSubmitOfflineForm,
  onTextInputChange,
  onToggleWidgetVisibility,
  sendButtonAlt,
  senderPlaceHolder,
  subtitle,
  titleAvatar,
}: WidgetLayoutProps) => {
  const dispatch = useDispatch();
  const {
    conversation: { currentImagePreview },
    user: { currentUser },
    settings: {
      isChatVisible,
      isInputDisabled,
      isOnline,
      branding: { theme, header },
    },
  } = useSelector((state: RootState) => ({
    settings: state.settings,
    conversation: state.conversation,
    user: state.user,
  }));

  const messageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isChatVisible) {
      messageRef.current = document.getElementById(
        'messages'
      ) as HTMLDivElement;

      dispatch(setNotificationCount(0));
    }
    return () => {
      messageRef.current = null;
    };
  }, [dispatch, isChatVisible]);

  const eventHandle = (evt: any) => {
    if (evt.target && evt.target.className === 'xqscw-message-img') {
      const {
        src,
        alt,
        naturalWidth,
        naturalHeight,
      } = evt.target as HTMLImageElement;
      const obj = {
        src: src,
        alt: alt,
        width: naturalWidth,
        height: naturalHeight,
      };
      dispatch(openImagePreview(obj));
    }
  };

  /**
   * Previewer needs to prevent body scroll settings when isImagePreviewMode is true
   */
  useEffect(() => {
    const target = messageRef?.current;
    if (currentImagePreview.visible && isChatVisible) {
      target?.addEventListener('click', eventHandle, false);
    }

    return () => {
      target?.removeEventListener('click', eventHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImagePreview.visible, isChatVisible]);

  useEffect(() => {
    document.body.setAttribute(
      'style',
      `overflow: ${currentImagePreview.visible ? 'hidden' : 'auto'}`
    );
  }, [currentImagePreview.visible]);

  return (
    <div
      id="xqscw-root"
      className={cn('xqscw-widget-container', {
        'xqscw-previewer': currentImagePreview.visible,
        'xqscw-close-widget-container ': !isChatVisible,
      })}
    >
      <div className={`xqscw-widget-${isChatVisible ? 'fade-in' : 'fade-out'}`}>
        <Conversation
          className={isChatVisible ? 'active' : 'hidden'}
          currentUser={currentUser}
          emojis={emojis}
          isAutoFocused={isAutoFocused}
          isInputDisabled={isInputDisabled}
          isOnline={isOnline}
          isResizeable={isResizeable}
          onDeleteMessage={onDeleteMessage}
          onQuickButtonClicked={onQuickButtonClicked}
          onSendMessage={onSendMessage}
          onSetCurrentUser={onSetCurrentUser}
          onSubmitOfflineForm={onSubmitOfflineForm}
          onTextInputChange={onTextInputChange}
          onToggleWidgetVisibility={onToggleWidgetVisibility}
          sendButtonAlt={sendButtonAlt}
          senderPlaceHolder={senderPlaceHolder}
          subtitle={subtitle}
          theme={theme}
          title={header}
          titleAvatar={titleAvatar}
          toggleWidgetVisibility={onToggleWidgetVisibility}
        />
      </div>
      {customLauncher
        ? customLauncher(onToggleWidgetVisibility)
        : !currentImagePreview.visible && (
            <Launcher
              isNotificationBadgeVisible={isNotificationBadgeVisible}
              openImg={launcherOpenImg}
              openLabel={launcherOpenLabel}
              onToggle={onToggleWidgetVisibility}
            />
          )}
      <ImagePreview
        src={currentImagePreview.src}
        visible={currentImagePreview.visible}
        onClose={() =>
          dispatch(
            setCurrentImagePreview({
              src: '',
              alt: '',
              width: 0,
              height: 0,
              visible: false,
            })
          )
        }
      />
    </div>
  );
};

export default WidgetLayout;
