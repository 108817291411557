import { createSlice } from '@reduxjs/toolkit';
import {
  adminSettingsDefaultProps,
  AdminSettingsStepProps,
} from 'src/features/XQSCWidget/types/AdminSettings';
import {
  brandingDefaultProps,
  BrandingStepProps,
} from 'src/features/XQSCWidget/types/Branding';

type DeviceTypeOptions = 'mobile' | 'desktop';

export enum DeviceType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export interface settingsSliceState {
  currentDeviceType: DeviceTypeOptions;
  branding: BrandingStepProps;
  isChatVisible: boolean;
  isInputDisabled: boolean;
  isOnline: boolean;
  isMessageLoaderToggled: boolean;
  workspaceId: number;
  adminSettings: AdminSettingsStepProps;
}

const initialState = () =>
  ({
    branding: brandingDefaultProps,
    isChatVisible: false,
    isInputDisabled: false,
    isOnline: false,
    isMessageLoaderToggled: false,
    adminSettings: adminSettingsDefaultProps,
    workspaceId: 0,
    currentDeviceType: DeviceType.DESKTOP,
  } as settingsSliceState);

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState(),
  reducers: {
    setBranding(state, action) {
      const branding = action.payload;
      state.branding = branding;
    },
    setCurrentDeviceType(state, action) {
      const currentDeviceType = action.payload;
      state.currentDeviceType = currentDeviceType;
    },
    toggleChatVisibility(state) {
      state.isChatVisible = !state.isChatVisible;
    },
    setInputDisabled(state, action) {
      const isInputDisabled = action.payload;
      state.isInputDisabled = isInputDisabled;
    },
    setMessageLoaderToggled(state, action) {
      const isMessageLoaderToggled = action.payload;
      state.isMessageLoaderToggled = isMessageLoaderToggled;
    },
    setOnline(state, action) {
      const isOnline = action.payload;
      state.isOnline = isOnline;
    },
    setWorkspaceId(state, action) {
      const workspaceId = action.payload;
      state.workspaceId = workspaceId;
    },
    setAdminSettings(state, action) {
      const adminSettings = action.payload;
      state.adminSettings = adminSettings;
    },

    resetSettingState: () => initialState(),
  },
});

export const {
  resetSettingState,
  setBranding,
  setCurrentDeviceType,
  setInputDisabled,
  setOnline,
  setAdminSettings,
  setWorkspaceId,
  toggleChatVisibility,
} = settingsSlice.actions;

export default settingsSlice.reducer;
