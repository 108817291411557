import ImageViewer from './ImageViewer';

type ImagePreviewProps = {
  src: string;
  visible: boolean;
  onClose: () => void;
};

const ImagePreview = ({ src, visible, onClose }: ImagePreviewProps) => {
  return (
    <>
      {visible && src && (
        <ImageViewer
          src={[src]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ImagePreview;
