import './Header.scoped.scss';

import { ReactComponent as HeaderIcon } from 'src/assets/svg/header-icon.svg';
import { ColorTheme } from 'src/features/XQSCWidget/types/Branding';
import CloseButton from '../../../shared/CloseButton';

type HeaderProps = {
  theme: ColorTheme;
  title: string;
  subtitle?: string;
  titleAvatar?: string;
  onToggleWidgetVisibility: () => void;
};

const Header = ({ theme, title, onToggleWidgetVisibility }: HeaderProps) => {
  return (
    <div
      className="xqscw-header"
      style={{ backgroundColor: theme.primaryThemeColor }}
    >
      <div className="xqscw-header-content">
        <div
          className="header-icon-container"
          style={{ background: theme.primaryTextColor }}
        >
          <HeaderIcon className="header-icon" fill={theme.primaryThemeColor} />
        </div>
        <span className="xqscw-title" style={{ color: theme.primaryTextColor }}>
          {title}
        </span>
      </div>
      <CloseButton
        onClick={onToggleWidgetVisibility}
        stroke={theme.primaryTextColor}
      />
    </div>
  );
};

export default Header;
