import { useDispatch, useSelector } from 'react-redux';

import { signInAnonymouslyViaFirebase } from 'src/services/auth';
import { authorizeAliasUserViaXQ } from 'src/services/XQSDK';
import { createNewUserViaFirestore } from 'src/services/auth';

import { AnyFunction } from 'src/utils/types';

import WidgetLayout from 'src/features/XQSCWidget/layout';

import {
  createAutomatedMessage,
  createConversation,
  createMessage,
  deleteMessage,
} from 'src/services/chat';

import {
  removeMessage,
  setCurrentConversation,
} from 'src/store/reducers/conversation';
import { useEffect } from 'react';
import { ProcessedMessage } from 'src/models/Message';

import { setCurrentUser, setUserToOnboard } from 'src/store/reducers/user';
import {
  setInputDisabled,
  toggleChatVisibility,
} from 'src/store/reducers/settings';
import { RootState } from 'src/store';
import { User } from 'src/models/User';
import { onSendMessage } from 'src/store/thunks/messages/onSendMessage';

type XQSCProps = {
  customLauncher?: AnyFunction;
  handleQuickButtonClicked?: AnyFunction;
  handleSubmit?: AnyFunction;
  handleTextInputChange?: (event: any) => void;
  isAutoFocused: boolean;
  isImagePreviewMode: boolean;
  isImagePreviewVisible?: boolean;
  isNotificationBadgeVisible?: boolean;
  isResizeable?: boolean;
  launcherOpenImg: string;
  launcherOpenLabel: string;
  sendButtonAlt: string;
  senderPlaceHolder: string;
  subtitle: string;
  titleAvatar?: string;
  zoomStep?: number;
};

const XQSCWidget = ({
  customLauncher,
  handleQuickButtonClicked,
  handleTextInputChange,
  isAutoFocused,
  isImagePreviewMode,
  isNotificationBadgeVisible,
  isResizeable,
  launcherOpenImg,
  launcherOpenLabel,
  sendButtonAlt,
  senderPlaceHolder,
  subtitle,
  titleAvatar,
}: XQSCProps) => {
  const dispatch = useDispatch();

  const {
    conversation: { automatedMessages, currentConversation },
    user: { userToOnboard, isOnboarded },
    settings: { adminSettings, workspaceId },
  } = useSelector((state: RootState) => ({
    conversation: state.conversation,
    user: state.user,
    settings: state.settings,
  }));

  useEffect(() => {
    if (isOnboarded) {
      dispatch(setInputDisabled(true));
      createAutomatedMessage(automatedMessages.intro);

      const runAuthenticationProcess = async () => {
        await authorizeAliasUserViaXQ(
          userToOnboard.email,
          async () => {
            try {
              await signInAnonymouslyViaFirebase();

              const savedUserDocument = await createNewUserViaFirestore(
                userToOnboard.email,
                userToOnboard.userName
              );

              dispatch(setCurrentUser(savedUserDocument));

              const newConversation = await createConversation(
                savedUserDocument as User,
                adminSettings.members,
                workspaceId
              );

              dispatch(setCurrentConversation(newConversation));
              dispatch(setInputDisabled(false));
            } catch (error) {
              console.log(error);
            }
          },
          () => console.log('Failure: Failed to Authorize user via XQSDK')
        );
      };
      runAuthenticationProcess();
    }
  }, [
    automatedMessages.intro,
    dispatch,
    isOnboarded,
    userToOnboard.email,
    userToOnboard.userName,
    adminSettings.members,
    workspaceId,
  ]);

  const onSetCurrentUser = (name?: string, email?: string) => {
    const updatedUserEmail = `${email || 'customer'}-${Date.now()}`;
    const userName = name ?? '';

    dispatch(setUserToOnboard({ email: updatedUserEmail, userName }));
  };

  /**
   * A function utilized to toggle the widget visibility, from launcher button to a visible widget view
   */
  const onToggleWidgetVisibility = () => dispatch(toggleChatVisibility());

  const onSubmitOfflineForm = async (
    name: string,
    email: string,
    messageInput: string
  ) => {
    return authorizeAliasUserViaXQ(
      email,
      async () => {
        try {
          await signInAnonymouslyViaFirebase();
          const formUser = (await createNewUserViaFirestore(
            email,
            name
          )) as User;

          const newConversation = await createConversation(
            formUser,
            adminSettings.members,
            workspaceId
          );

          await createMessage(
            newConversation,
            formUser,
            {
              messageInput,
            },
            dispatch
          );
        } catch (error) {
          console.log(error);
        }
      },
      () => console.log('Failure: Failed to Authorize user via XQSDK')
    );
  };

  const onDeleteMessage = async (message: ProcessedMessage) => {
    await deleteMessage(
      currentConversation.id,
      message.id,
      message.locatorToken
    );

    return dispatch(removeMessage(message));
  };

  const onQuickButtonClicked = (
    event: { preventDefault: () => void },
    value: any
  ) => {
    event.preventDefault();
    handleQuickButtonClicked?.(value);
  };

  return (
    <WidgetLayout
      customLauncher={customLauncher}
      isAutoFocused={isAutoFocused}
      isImagePreviewMode={isImagePreviewMode}
      isNotificationBadgeVisible={isNotificationBadgeVisible}
      isResizeable={isResizeable}
      launcherOpenImg={launcherOpenImg}
      launcherOpenLabel={launcherOpenLabel}
      onDeleteMessage={onDeleteMessage}
      onQuickButtonClicked={onQuickButtonClicked}
      onSendMessage={() => dispatch(onSendMessage())}
      onSetCurrentUser={onSetCurrentUser}
      onSubmitOfflineForm={onSubmitOfflineForm}
      onTextInputChange={handleTextInputChange}
      onToggleWidgetVisibility={onToggleWidgetVisibility}
      sendButtonAlt={sendButtonAlt}
      senderPlaceHolder={senderPlaceHolder}
      subtitle={subtitle}
      titleAvatar={titleAvatar}
    />
  );
};

export default XQSCWidget;
