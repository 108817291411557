export interface ColorTheme {
  primaryThemeColor: string;
  secondaryThemeColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
}
export interface BrandingStepProps {
  theme: ColorTheme;
  header: string;
}

export const brandingDefaultProps = {
  theme: {
    primaryThemeColor: '#0084ff',
    secondaryThemeColor: '#7fc1ff',
    primaryTextColor: '#ffffff',
    secondaryTextColor: '#ffffff',
  },
  header: 'Your Chat Header',
};
