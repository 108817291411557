export enum AvailabilityOptions {
  AlwaysOnline = 'Availability.alwaysOnline',
  AlwaysOffline = 'Availability.alwaysOffline',
  Custom = 'Availability.custom',
}

export type AvailabilityTimeRange = {
  isActive: boolean;
  name: string;
  abbr: string; // label for day of week
  day: number; // index for day of week
  start: number; // start time of day (in mins)
  end: number; // end time of day (in mins)
};

export interface AvailabilitySchedule {
  availabilityTimeRange: AvailabilityTimeRange[];
  timeZone: string;
}

export interface AutomatedMessageConfig {
  intro: string;
  offline: string;
}

export interface CustomerInteractionStepProps {
  type: AvailabilityOptions;
  schedule: AvailabilitySchedule;
  automatedMessages: AutomatedMessageConfig;
}

export const customerInteractionDefaultProps: CustomerInteractionStepProps = {
  type: AvailabilityOptions.AlwaysOnline,
  schedule: {
    availabilityTimeRange: [
      {
        isActive: true,
        name: 'Sunday',
        abbr: 'SUN',
        day: 0,
        start: 0,
        end: 1440,
      },
      {
        isActive: true,
        name: 'Monday',
        abbr: 'M',
        day: 1,
        start: 0,
        end: 1440,
      },
      {
        isActive: true,
        name: 'Tuesday',
        abbr: 'T',
        day: 2,
        start: 0,
        end: 1440,
      },
      {
        isActive: true,
        name: 'Wednesday',
        abbr: 'W',
        day: 3,
        start: 0,
        end: 1440,
      },
      {
        isActive: true,
        name: 'Thursday',
        abbr: 'TH',
        day: 4,
        start: 0,
        end: 1440,
      },
      {
        isActive: true,
        name: 'Friday',
        abbr: 'F',
        day: 5,
        start: 0,
        end: 1440,
      },
      {
        isActive: true,
        name: 'Saturday',
        abbr: 'SAT',
        day: 6,
        start: 0,
        end: 1440,
      },
    ],
    timeZone: '',
  },
  automatedMessages: {
    intro:
      'Hello! While we connect you with a live chat support team member, please tell us your name, email, and a description of your issue.',
    offline:
      'We are currently offline. Our support hours are 9am - 5pm PST. Please submit your inquiry, and we will get back to you via email once our hours resume.',
  },
};
