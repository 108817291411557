import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';

interface IProps {
  src: string[];
  currentIndex?: number;
  backgroundStyle?: CSSProperties;
  disableScroll?: boolean;
  closeOnClickOutside?: boolean;
  onClose?: () => void;
  closeComponent?: any;
  leftArrowComponent?: any;
  rightArrowComponent?: any;
}

const ImageViewer = (props: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex ?? 0);

  const changeImage = useCallback(
    (delta: number) => {
      let nextIndex = (currentIndex + delta) % props.src.length;
      if (nextIndex < 0) nextIndex = props.src.length - 1;
      setCurrentIndex(nextIndex);
    },
    [currentIndex, props.src.length]
  );

  const handleClick = useCallback(
    (event: any) => {
      if (!event.target || !props.closeOnClickOutside) {
        return;
      }

      const checkId = event.target.id === 'ImageViewer';
      const checkClass = event.target.classList.contains('image_viewer__slide');

      if (checkId || checkClass) {
        event.stopPropagation();
        props.onClose?.();
      }
    },
    [props]
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Escape') {
        props.onClose?.();
      }

      if (['ArrowLeft', 'h'].includes(event.key)) {
        changeImage(-1);
      }

      if (['ArrowRight', 'l'].includes(event.key)) {
        changeImage(1);
      }
    },
    [props, changeImage]
  );

  const handleWheel = useCallback(
    (event: any) => {
      if (event.wheelDeltaY > 0) {
        changeImage(-1);
      } else {
        changeImage(1);
      }
    },
    [changeImage]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    if (!props.disableScroll) {
      document.addEventListener('wheel', handleWheel);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);

      if (!props.disableScroll) {
        document.removeEventListener('wheel', handleWheel);
      }
    };
  }, [handleKeyDown, handleWheel, props.disableScroll]);

  return (
    <div
      id="ImageViewer"
      className={`${styles.wrapper} image_viewer__modal`}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      style={props.backgroundStyle}
    >
      <span
        className={`${styles.close} image_viewer__close`}
        onClick={() => props.onClose?.()}
      >
        {props.closeComponent || '×'}
      </span>

      {props.src.length > 1 && (
        <span
          className={`${styles.navigation} ${styles.prev} image_viewer__previous`}
          onClick={() => changeImage(-1)}
        >
          {props.leftArrowComponent || '❮'}
        </span>
      )}

      {props.src.length > 1 && (
        <span
          className={`${styles.navigation} ${styles.next} image_viewer__next`}
          onClick={() => changeImage(1)}
        >
          {props.rightArrowComponent || '❯'}
        </span>
      )}

      <div
        className={`${styles.content} image_viewer__modal-content`}
        onClick={handleClick}
      >
        <div className={`${styles.slide} image_viewer__slide`}>
          <img className={styles.image} src={props.src[currentIndex]} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;
