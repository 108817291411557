import React from 'react';

import './OptionMenu.scoped.scss';

import { ReactComponent as KebabMenu } from '../../../../../../../../assets/svg/kebab-menu-icon.svg';

interface OptionMenuProps {
  isCurrentUser: boolean;
  isVisible: boolean;
  showDropdown: boolean;
  onSetShowDropdown: () => void;
  container: React.RefObject<HTMLDivElement>;
  onCopyMessageImage?: () => void;
  onCopyMessageText?: () => void;
  onDeleteMessage?: () => void;
  onDownloadFile?: () => Promise<void>;
}

/**
 * A functional component which renders the popup component used by users to interact with relevant messages.
 * @param isVisible - a boolean value which represents the `OptionMenu` visibility
 * @param onDeleteMessage - a function which is used to delete the specified message
 * @param onDownloadFile - a function which is used to download a file attachment associated with the message
 * @returns React.FC
 */
const OptionMenu: React.FC<OptionMenuProps> = ({
  isCurrentUser,
  isVisible,
  container,
  showDropdown,
  onSetShowDropdown,
  // onCopyMessageImage,
  onCopyMessageText,
  onDeleteMessage,
  onDownloadFile,
}) => {
  if (!isVisible) {
    return null;
  }

  const getTransform = () => {
    if (!isCurrentUser && container.current) {
      if (container.current.clientWidth > 200) {
        return '0';
      }

      return '75%';
    }

    if (isCurrentUser && container.current) {
      if (container.current.clientWidth > 200) {
        return '75%';
      }

      if (container.current.clientWidth <= 200) {
        return '0';
      }
    }
  };

  const renderOwnerOption = () => {
    return (
      <div>
        <button className="dropdown-button" onClick={onSetShowDropdown}>
          <KebabMenu height={25} width={25} color="#202020" />
        </button>
        {showDropdown && (
          <div
            className="dropdown-content"
            style={{
              right: 0,
              transform: `translate(${getTransform()})`,
            }}
          >
            {onCopyMessageText && (
              <button className="copy-text" onClick={onCopyMessageText}>
                Copy
              </button>
            )}
            {onDownloadFile && (
              <button className="download-file" onClick={onDownloadFile}>
                Download
              </button>
            )}
            {isCurrentUser ? (
              <button className="delete-text" onClick={onDeleteMessage}>
                Delete message
              </button>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  const currentUserStyle = isCurrentUser
    ? {
        left: -25,
      }
    : {
        right: -25,
      };

  return (
    <div
      style={{
        ...currentUserStyle,
        zIndex: 5,
      }}
      className="option-menu"
    >
      {renderOwnerOption()}
    </div>
  );
};

export default OptionMenu;
