import React from 'react';

import { Line } from 'rc-progress';

import { ReactComponent as NonViewablefileAttachmentIcon } from '../../../../../../../../assets/svg/non-viewable-file-attachment.svg';
import { ReactComponent as VideoAttachmentIcon } from '../../../../../../../../assets/svg/video-file-attachment.svg';

import { FileResult } from 'src/store/reducers/conversation';

import './FileAttachmentList.scoped.scss';

interface FileAttachmentListProps {
  fileAttachments: FileResult[];
  fileUploadProgress: number;
  onAddFileAttachment: () => void;
  onRemoveFileAttachment: (fileAttachment: FileResult) => void;
}

const FileAttachmentList: React.FC<FileAttachmentListProps> = ({
  fileAttachments,
  fileUploadProgress,
  onAddFileAttachment,
  onRemoveFileAttachment,
}) => {
  const renderThumbnail = (fileAttachment: FileResult) => {
    if (fileAttachment.file.type.includes('video')) {
      return <VideoAttachmentIcon className="file-attachment-list-item" />;
    }
    if (fileAttachment.file.type.includes('image')) {
      return (
        <img
          src={fileAttachment.preview}
          alt=""
          className="file-attachment-list-item"
        />
      );
    }

    return (
      <NonViewablefileAttachmentIcon className="file-attachment-list-item" />
    );
  };
  return (
    <div className="main-container">
      {fileUploadProgress > 0 && (
        <Line
          percent={fileUploadProgress}
          strokeWidth={2}
          strokeColor="#0084ff"
          style={{ padding: '0 12px 5px 12px', width: '75%' }}
        />
      )}
      {fileAttachments.length > 0 && (
        <div className="file-attachment-list">
          {fileAttachments.map((fileAttachment, index) => (
            <div
              className="file-attachment-list-item-container"
              style={{ position: 'relative' }}
              key={index}
            >
              <span
                onClick={() => onRemoveFileAttachment(fileAttachment)}
                className="remove-icon"
                role="button"
                tabIndex={0}
                onKeyDown={() => onRemoveFileAttachment(fileAttachment)}
              >
                &times;
              </span>
              {renderThumbnail(fileAttachment)}
            </div>
          ))}
          <div
            className="add-attachment-item"
            onClick={onAddFileAttachment}
            onKeyDown={onAddFileAttachment}
            role="button"
            tabIndex={0}
          >
            +
          </div>
        </div>
      )}
    </div>
  );
};

export default FileAttachmentList;
