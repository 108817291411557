import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';

import { ConfigWizardState } from 'src/features/XQSCWidget/types/ConfigWizardState';
import { AvailabilityOptions } from 'src/features/XQSCWidget/types/CustomerInteraction';

import determineWidgetOnlineStatus from 'src/features/XQSCWidget/utils/determineWidgetOnlineStatus';
import { fetchConfigViaCloudFunction } from 'src/services/firebase';

import { setAutomatedMessages } from 'src/store/reducers/conversation';
import {
  setAdminSettings,
  setBranding,
  setOnline,
  setWorkspaceId,
} from 'src/store/reducers/settings';

/**
 * A React hook utilized to fetch widget configuration (styling/branding, schedule, team) via ID from firestore collection "widget-configuration"
 * @param id - the ID of the widget configuration stored in firestore
 * @returns boolean value representing whether or not the configuration as been fetched and uploaded to redux
 */
export const useLoadConfig = (id: string) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfig = async () => {
      const fetchedConfiguration = await fetchConfigViaCloudFunction(id);

      const bytes = CryptoJS.AES.decrypt(
        fetchedConfiguration,
        process.env.REACT_APP_EMBED_CONFIG_ENCRYPTION_KEY as string
      );

      const DECRYPTED_CONFIG = bytes.toString(CryptoJS.enc.Utf8);

      const widgetConfig = JSON.parse(DECRYPTED_CONFIG) as ConfigWizardState;

      let isOnline =
        widgetConfig.customerInteraction.type ===
        AvailabilityOptions.AlwaysOnline
          ? true
          : false;

      if (
        widgetConfig.customerInteraction.type === AvailabilityOptions.Custom
      ) {
        isOnline = determineWidgetOnlineStatus(
          widgetConfig.customerInteraction
        );
      }

      dispatch(setBranding(widgetConfig.branding));
      dispatch(setAdminSettings(widgetConfig.adminSettings));
      dispatch(setWorkspaceId(widgetConfig.workspaceId));
      dispatch(setOnline(isOnline));
      dispatch(
        setAutomatedMessages(widgetConfig.customerInteraction.automatedMessages)
      );

      setLoaded(true);
    };

    if (id) {
      fetchConfig();
    }
  }, [dispatch, id]);

  return isLoaded;
};
