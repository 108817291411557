import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { initializeApp } from 'firebase/app';
import { User, UserModelFields } from 'src/models/User';
import { getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  UploadTask,
} from 'firebase/storage';

import {
  signInAnonymously as ANONYMOUS_SIGN_IN,
  signOut as ANONYMOUS_SIGN_OUT,
} from 'firebase/auth';
import { ConversationDocument } from 'src/models/Conversation';
import { Dispatch } from 'redux';
import { setFileUploadProgress } from 'src/store/reducers/conversation';

/** An interface for the Firebase configuration object. Contains a set of parameters required
 * by services in order to successfully communicate with Firebase server APIs
 * and to associate client data with our Firebase project and Firebase application */
interface FirebaseConfigOptions {
  apiKey: string;
  appId: string;
  authDomain: string;
  measurementId: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
}

/**
 * The Firebase app config Options object for the development environment used to enable the relevant environments firebase services
 */
const firebaseConfiguration: FirebaseConfigOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID ?? '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? '',
};

export type FirestoreTimestamp = firebase.firestore.Timestamp;

/**
 * A function used to create and initialize a Firebase app instance
 * @returns `firebase.app.App`
 */
export const firebaseInitializeApp = () => {
  return initializeApp(firebaseConfiguration);
};

/** The Firebase app instance */
export const initializedFirebaseClient = firebaseInitializeApp();

/** An interface to access the Firebase Auth service */
export const AUTH = getAuth(initializedFirebaseClient);

/** An interface to access the Firebase Firestore service */
export const FIRESTORE = getFirestore(initializedFirebaseClient);

/** An interface to access the Firebase Cloud Storage service */
const storage = getStorage(initializedFirebaseClient);

/**
 * An enum list of all firestore collection paths used in the app
 */
export enum AvailableCollections {
  CONVERSATIONS = 'conversations',
  MESSAGES = 'messages',
  USERS = 'users',
}

/**
 * A Timestamp represents a point in time independent of any time zone or calendar,
 * represented as seconds and fractions of seconds at nanosecond resolution in UTC Epoch time
 */
export type FirebaseTimestamp = firebase.firestore.Timestamp;

/**
 * A function utilized to set the timestamp in Firestore format.
 * @returns `Date` object
 */
export const setTimestamp = () => firebase.firestore.Timestamp.now();

/**
 * A function utilized to set the expiry timestamp in Firestore format
 * @returns `Date` object
 */
export const setExpiresAtTimestamp = (hours: number) => {
  const createdAt = firebase.firestore.Timestamp.now().toDate();
  createdAt.setSeconds(createdAt.getSeconds() + 60 * 60 * hours);
  return firebase.firestore.Timestamp.fromDate(createdAt);
};

export const fetchUser = async (userId: string): Promise<User> => {
  const userDocRef = doc(FIRESTORE, AvailableCollections.USERS, userId);
  const userSnapshot = (await getDoc(userDocRef)).data() as User;

  return userSnapshot;
};

/**
 * A function utilized to fetch a `user` document from the `users` collection via email
 * @param email - the user's email
 * @returns `User`, a user document.
 */
export const fetchUserByEmail = async (email: string) => {
  const q = query(
    collection(FIRESTORE, AvailableCollections.USERS),
    where(UserModelFields.EMAIL, '==', email)
  );

  const userQuerySnapshot = await getDocs(q);

  if (userQuerySnapshot.docs[0]) {
    return (userQuerySnapshot.docs[0].data() as unknown) as User;
  }

  return null;
};

/**
 * A function utilized to fetch a `conversation` document from the `conversations` collection via conversation id
 * @param conversationId - id of the conversation
 * @returns
 */
export const fetchConversation = async (
  conversationId: string
): Promise<ConversationDocument> => {
  const conversationDocRef = doc(
    FIRESTORE,
    AvailableCollections.CONVERSATIONS,
    conversationId
  );
  const conversationSnapshot = (
    await getDoc(conversationDocRef)
  ).data() as ConversationDocument;

  return conversationSnapshot;
};

/**
 * a single map collection of all firestore operations used in the app
 */
export const firestoreOperations = {
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
};

/**
 * a single map collection of all firebase authentication operations used in the app
 */
export const firebaseAuthOperations = {
  ANONYMOUS_SIGN_IN,
  ANONYMOUS_SIGN_OUT,
};

/**
 * A function utilized to fetch widget configuration via firestore by ID. We use cloud functions to handle this operation.
 * @param id
 * @returns
 */
export const fetchConfigViaCloudFunction = async (id: string) => {
  const url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_HOST}/getWidgetConfiguration`;

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      id,
    }),
  };

  const request = await fetch(url, options);
  const response = await request.json();

  const payload = response.payload;

  return payload;
};

const fetchEncryptedFileAttachment = async (
  encryptedFile: File,
  sourceFile: File,
  uploadTask: UploadTask
) => {
  const fileAttachmentUrl = await getDownloadURL(uploadTask.snapshot.ref);

  const encryptedFileAttachment = {
    url: fileAttachmentUrl,
    type: sourceFile.type,
    name: encryptedFile.name,
    size: encryptedFile.size,
  };

  return encryptedFileAttachment;
};

export const uploadEncryptedFile = async (
  encryptedFile: File,
  sourceFile: File,
  dispatch: Dispatch
) => {
  if (!(encryptedFile instanceof File)) {
    throw new Error('There was an issue encrypting the supplied file');
  }
  return new Promise((resolve, _reject) => {
    const storageRef = ref(storage, `/messages/${encryptedFile.name}`);

    const uploadTask = uploadBytesResumable(storageRef, encryptedFile);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    return uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');

        dispatch(setFileUploadProgress(progress));

        // TODO(worstestes): development purposes below
        // switch (snapshot.state) {
        //   case 'paused':
        //     console.log('Upload is paused');
        //     break;
        //   case 'running':
        //     console.log('Upload is running');
        //     break;
        // }
      },
      console.error,
      async () => {
        const encryptedFileAttachment = await fetchEncryptedFileAttachment(
          encryptedFile,
          sourceFile,
          uploadTask
        );
        dispatch(setFileUploadProgress(0));
        resolve(encryptedFileAttachment);
      }
    );
  });
};
