import { createSlice } from '@reduxjs/toolkit';
import { baseUserDocument, User } from 'src/models/User';

export interface userSliceState {
  currentUser: User;
  userToOnboard: { email: string; userName: string };
  isOnboarded: boolean;
}

const initialState = () =>
  ({
    currentUser: baseUserDocument,
    userToOnboard: {
      email: '',
      userName: '',
    },
    isOnboarded: false,
  } as userSliceState);

const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    setCurrentUser(state, action) {
      const user = action.payload;
      state.currentUser = user;
    },
    setUserToOnboard(state, action) {
      const userToOnboard = action.payload;
      state.isOnboarded = true;
      state.userToOnboard = userToOnboard;
    },

    resetUserState: () => initialState(),
  },
});

export const {
  setCurrentUser,
  setUserToOnboard,
  resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
