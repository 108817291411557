import { createSlice } from '@reduxjs/toolkit';
import {
  AutomatedMessageConfig,
  customerInteractionDefaultProps,
} from 'src/features/XQSCWidget/types/CustomerInteraction';
import {
  baseConversationDocument,
  ProcessedConversation,
} from 'src/models/Conversation';
import {
  EncryptedMessageDocument,
  MessageState,
  ProcessedMessage,
} from 'src/models/Message';

type ImagePreviewState = {
  src: string;
  alt: string;
  width: number;
  height: number;
  visible: boolean;
};

export interface FileResult {
  name: string;
  file: File;
  canView: boolean;
  preview: string;
}

export interface SupportTeamUser {
  email: string;
  inWorkspace: boolean;
}

export interface conversationSliceState {
  automatedMessages: AutomatedMessageConfig;
  currentConversation: ProcessedConversation;
  currentImagePreview: ImagePreviewState;
  fileAttachments: FileResult[];
  fileUploadProgress: number;
  isSendingMessage: boolean;
  messageLoaders: EncryptedMessageDocument[];
  messageInput: string;
  messages: ProcessedMessage[];
  notificationCount: number;
}

const initialState = () =>
  ({
    automatedMessages: customerInteractionDefaultProps.automatedMessages,
    currentConversation: (baseConversationDocument as unknown) as ProcessedConversation,
    currentImagePreview: {
      src: '',
      alt: '',
      width: 100,
      height: 100,
      visible: false,
    },
    fileAttachments: [],
    fileUploadProgress: 0,
    isSendingMessage: false,
    messageLoaders: [],
    messageInput: '',
    messages: [],
    notificationCount: 0,
  } as conversationSliceState);

const arrayUniqueByKey = (
  arr: ProcessedMessage[] | EncryptedMessageDocument[]
) => [
  ...new Map(
    arr.map((item: ProcessedMessage | EncryptedMessageDocument) => [
      item.id,
      item,
    ])
  ).values(),
];

const conversationSlice = createSlice({
  name: 'conversation',
  initialState: initialState(),
  reducers: {
    addMessage(state, action) {
      const message = action.payload;
      state.messages = arrayUniqueByKey([...state.messages, message]).sort(
        (a, b) => a.date.seconds - b.date.seconds
      ) as ProcessedMessage[];
    },
    addMessageLoader(state, action) {
      const messageLoader = action.payload;
      state.messageLoaders = arrayUniqueByKey([
        ...state.messageLoaders,
        messageLoader,
      ]).sort(
        (a, b) => a.date.seconds - b.date.seconds
      ) as EncryptedMessageDocument[];
    },
    removeFileAttachment(state, action) {
      const fileToRemove = action.payload;

      state.fileAttachments = state.fileAttachments.filter(
        fileAttachment => fileAttachment.preview !== fileToRemove.preview
      );
    },
    removeMessage(state, action) {
      const message = action.payload;
      state.messages = [...state.messages]
        .map(msg => {
          const updatedState =
            msg.id === message.id ? MessageState.DELETED : msg.state;

          return {
            ...msg,
            state: updatedState,
          };
        })
        .sort((a, b) => a.date.seconds - b.date.seconds);
    },
    removeMessageLoader(state, action) {
      const messageLoader = action.payload;
      state.messageLoaders = [...state.messageLoaders]
        .filter(msgLoader => msgLoader.id !== messageLoader.id)
        .sort((a, b) => a.date.seconds - b.date.seconds);
    },
    setAutomatedMessages(state, action) {
      const automatedMessages = action.payload;
      state.automatedMessages = automatedMessages;
    },
    setCurrentConversation(state, action) {
      const currentConversation = action.payload;
      state.currentConversation = currentConversation;
    },
    setCurrentImagePreview(state, action) {
      const currentImagePreview = action.payload;
      state.currentImagePreview = currentImagePreview;
    },
    setFileAttachments(state, action) {
      const fileAttachments = action.payload;
      state.fileAttachments = fileAttachments;
    },
    setFileUploadProgress(state, action) {
      const fileUploadProgress = action.payload;
      state.fileUploadProgress = fileUploadProgress;
    },
    setIsSendingMessage(state, action) {
      const isSendingMessage = action.payload;
      state.isSendingMessage = isSendingMessage;
    },
    setMessageInput(state, action) {
      const messageInput = action.payload;
      state.messageInput = messageInput;
    },
    setNotificationCount(state, action) {
      const addedNotification = action.payload;

      if (addedNotification === 0) {
        state.notificationCount = 0;
      } else {
        state.notificationCount = state.notificationCount + 1;
      }
    },

    openImagePreview(_state, _action) {},
    closeImagePreview(_state, _action) {},

    resetConversationState: () => initialState(),
  },
});

export const {
  addMessage,
  addMessageLoader,
  closeImagePreview,
  openImagePreview,
  removeFileAttachment,
  removeMessage,
  removeMessageLoader,
  resetConversationState,
  setAutomatedMessages,
  setCurrentConversation,
  setCurrentImagePreview,
  setFileAttachments,
  setFileUploadProgress,
  setIsSendingMessage,
  setMessageInput,
  setNotificationCount,
} = conversationSlice.actions;

export default conversationSlice.reducer;
