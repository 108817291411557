import React, { useEffect } from 'react';

import { MessageDocument } from 'src/models/Message';

import { ReactComponent as NonViewableFileAttachmentIcon } from '../../../../../../../../assets/svg/non-viewable-file-attachment.svg';

import SVGComponent from './SVGComponent';
import { isSafari } from 'src/utils/detectBrowser';

import ReactPlayer from 'react-player';

interface FileAttachmentProps {
  isImageExpanded?: boolean;
  message: MessageDocument;
  onExpandImage?: (messageId: string) => void;
  onImageClick: (imgSrc: string) => void;
  handleScrollToBottom?: () => void;
}

/**
 * A functional component utilized to display a `FileAttachment` from a `Message`
 * @param message - A `ProcessedMessage` (processed `MessageDocument` from Firestore)
 */
const FileAttachment: React.FC<FileAttachmentProps> = ({
  message,
  onImageClick,
  handleScrollToBottom,
}) => {
  useEffect(() => {
    // DisplayMessage.tsx shouldn't call scrollToBottom
    if (!handleScrollToBottom) return;
    // Scroll to bottom once videos have loaded
    const video = document?.getElementsByClassName('video-player')[0];
    video?.addEventListener('loadeddata', () => handleScrollToBottom(), false);

    return () => {
      video?.removeEventListener('loadeddata', () => handleScrollToBottom());
    };
  }, [handleScrollToBottom]);

  const { fileAttachment } = message;

  const isVideo = fileAttachment.type.includes('video');
  const isImage = fileAttachment.type.includes('image');

  const isViewable = isVideo || isImage;

  const determineImageWidth = () => {
    if (isViewable) {
      return 200;
    }
    return '10em';
  };

  /**
   * A function which renders a video from a given file attachment.
   * @returns `JSX.Element | null`
   */
  const renderVideoPlayer = () => {
    if (isVideo) {
      /**
       * @description
       * isSafari boolean is true on chrome
       * */
      if (isSafari) {
        return (
          <video
            controls
            style={{
              width: '100%',
              borderRadius: 5,
            }}
            className="video-player"
          >
            <source
              src={fileAttachment.url}
              type={
                fileAttachment.type === 'video/quicktime'
                  ? 'video/mp4'
                  : fileAttachment.type
              }
            />
          </video>
        );
      }

      return (
        <ReactPlayer
          url={fileAttachment.url}
          style={{
            width: 100,
            borderRadius: 5,
            overflow: 'hidden',
          }}
          width="100%"
          height="100%"
          controls
        />
      );
    }
    return null;
  };

  /**
   * A function which renders an image for a given file attachment.
   *
   * If the file attachment is an image, we display the image, else a generic non-viewable file attachment icon
   *
   * If the image is an `.svg`, we display it as an `SVGComponent`.
   *
   * @returns `JSX.Element | null`
   */
  const renderFileAttachmentImage = () => {
    if (isImage) {
      if (fileAttachment.type.includes('svg')) {
        if (isSafari) {
          return <NonViewableFileAttachmentIcon />;
        }
        return (
          <SVGComponent
            url={fileAttachment?.url}
            style={{
              width: determineImageWidth(),
              cursor: 'pointer',
            }}
            onClick={() => {}}
          />
        );
      }

      if (!isViewable) {
        return <NonViewableFileAttachmentIcon />;
      }

      return (
        <img
          onLoad={() => (handleScrollToBottom ? handleScrollToBottom() : null)}
          onClick={() =>
            isImage ? onImageClick(message.fileAttachment.url) : null
          }
          src={fileAttachment.url}
          alt="file-attachment"
          style={{
            width: determineImageWidth(),
            cursor: 'pointer',
          }}
        />
      );
    }

    if (!isViewable) {
      return <NonViewableFileAttachmentIcon />;
    }
  };

  return (
    <div
      style={{
        marginBottom: 8,
        border: isViewable ? '' : 'solid 1.5px #f0f0f0',
        padding: isViewable ? '1px' : '16px 11.4px 14px 16px',
        borderRadius: isViewable ? '' : '6',
      }}
    >
      {renderVideoPlayer()}
      {renderFileAttachmentImage()}
      <div
        style={{
          fontStyle: 'italic',
          fontWeight: 500,
          overflowWrap: 'break-word',
          width: '100%',
          wordBreak: 'break-all',
        }}
      >
        {fileAttachment.name}
      </div>
      {!isViewable && (
        <div
          style={{
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 500,
            opacity: 0.5,
          }}
        >
          {fileAttachment.size} bytes
        </div>
      )}
    </div>
  );
};

export default FileAttachment;
