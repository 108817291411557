import { CustomerInteractionStepProps } from '../types/CustomerInteraction';
import { utcToZonedTime } from 'date-fns-tz';

/**
 * A function utilized to determine the online/offline status of the widget. This is determined by the widget configuration set by the admin user in the configuration workflow of the XQ Dev Portal.
 *
 * @param customerInteraction - `CustomerInteractionStepProps`
 * @returns boolean - true === online
 */
const determineWidgetOnlineStatus = (
  customerInteraction: CustomerInteractionStepProps
) => {
  const widgetAvailabilityTimeRange =
    customerInteraction.schedule.availabilityTimeRange;
  const widgetAvailabilityTimezone = customerInteraction.schedule.timeZone;
  const currentDayOfWeek = new Date().getDay();

  const dayIsActive =
    widgetAvailabilityTimeRange[currentDayOfWeek] &&
    widgetAvailabilityTimeRange[currentDayOfWeek].isActive;

  const currentDayAvailability = widgetAvailabilityTimeRange[currentDayOfWeek];

  if (dayIsActive) {
    const today = new Date();

    const timeAdjustedForTimezone = utcToZonedTime(
      today,
      widgetAvailabilityTimezone
    );

    const timeInHoursToMinutes = timeAdjustedForTimezone.getHours() * 60;
    const timeInMinutes = timeAdjustedForTimezone.getMinutes();

    const totalMinutes = timeInHoursToMinutes + timeInMinutes;

    if (
      totalMinutes >= currentDayAvailability.start &&
      totalMinutes <= currentDayAvailability.end
    ) {
      return true;
    }
  }
  return false;
};

export default determineWidgetOnlineStatus;
