import * as React from 'react';
import cn from 'classnames';

import { AnyFunction } from 'src/utils/types';

import './Button.scoped.scss';

interface ButtonProps {
  isDisabled: boolean;
  onClick: AnyFunction;
  style?: Record<string, string>;
  disableHoverEffect?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  isDisabled,
  onClick,
  style,
  disableHoverEffect,
}) => (
  <button
    className={cn(`${disableHoverEffect ? 'button-disabled-hover' : 'button'}`)}
    disabled={isDisabled}
    onClick={onClick}
    style={isDisabled ? {} : style}
  >
    {children}
  </button>
);

export default Button;
